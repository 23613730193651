import React from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "../assets/styles/Contactus.css";
import "../assets/styles/Contactus.css";
import { Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
function ContactUs({ isHidetitle }) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [isApiCall, setIsApiCall] = React.useState(false);

  const handleContactUs = async (e) => {
    e?.preventDefault();

    const params = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    for (let key in params)
      if (params[key].trim() === "") return toast.warn(`${key} is required.`);

    const url = BaseURL("users/contactus");
    setIsApiCall(true);
    const response = await Post(url, params, apiHeader());
    setIsApiCall(false);

    if (response !== undefined) {
      setEmail("");
      setName("");
      setMessage("");
      setPhone("");
      toast.success("Your message has been sent successfully.");
    }
  };

  return (
    <div className={isApiCall && "op-0"}>
      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="Contact">
                <div className="Contactus-section" data-aos="fade-up">
                  {!isHidetitle && (
                    <>
                      <h1>Contact Us</h1>
                      <p className="mid">
                        Email us through your construction drawings and
                        specifications at any time. Samples of our past works
                        are available upon request.
                      </p>
                    </>
                  )}
                </div>
                <div className="Contact-box">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        className="input-box"
                        type="email"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Control
                        className="input-box"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control
                        className="input-box"
                        type="text"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone((e.target.value).replace(/\D/g,''))}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <div className="Contactbtn" data-aos="zoom-out-up">
                    <button onClick={handleContactUs} disabled={isApiCall}>
                      {isApiCall ? "Please Wait..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default ContactUs;
