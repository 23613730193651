import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import "../assets//styles/Dashboardpage.css";
import "../assets/styles/customerdashboard.css";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import SideNavBar from "../components/SideNavBar";
import Header from "../components/header";
import MessageSideBar from "../components/MessageSideBar";
import ProjectCards from "../components/ProjectCards";
import Payment from "../components/Payement";
import NullDataComponent from "../components/NullDataComponent";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import { isMobileViewHook } from "../hooks/IsMobileViewHook";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import CardLoader from "../components/CardLoader";
import TableRowsLoader from "../components/TableRowsLoader";
export default function CustomerDashboard() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 },
  ];
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [newProposals, setNewProposals] = useState([]);
  const [payments, setPayments] = useState([]);
  const [projects, setProjects] = useState([]);

  async function getData() {
    const url = BaseURL("freelancer/dashboardData");
    setIsApiCall(true);
    const response = await Get(url, accessToken, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setNewProposals(data?.newProposals);
      setPayments(data?.payments);
      setProjects(data?.assignProjects);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    isMobileViewHook(setIsMobile);
  }, []);

  return (
    <>
      <Header lg />
      {/* <section className="Dashboardpage-section"> */}
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col lg={9} md={12}>
            <Col lg={12}>
              <div className="Dashboardpage-project">
                <h2>My Projects</h2>
                {projects?.length > 0 && (
                  <div className="Dashboardpage-project-btn">
                    <button
                      onClick={() => {
                        history.push("/AssignedProject");
                      }}
                    >
                      View More
                    </button>
                  </div>
                )}
              </div>

              {isApiCall ? (
                <CardLoader size={400} />
              ) : (
                <>
                  {/* My Projects */}
                  {projects.length > 1 ? (
                    <Col md={12}>
                      {!isMobile && projects?.length > 0 && (
                        <div className="projectCard-box">
                          <Carousel
                            itemsToScroll={1}
                            pagination={false}
                            breakPoints={breakPoints}
                            itemPadding={[10, 20]}
                          >
                            {projects?.map((items, index) => {
                              return (
                                <ProjectCards
                                  key={items?._id}
                                  title={
                                    items?.accecptedPorposalByFreelancer?.title
                                  }
                                  description={
                                    items?.accecptedPorposalByFreelancer
                                      ?.description
                                  }
                                  amount={
                                    items?.accecptedPorposalByFreelancer?.amount
                                  }
                                  timeline={
                                    items?.timeline || "Discuss on Chat"
                                  }
                                  projectStatus={
                                    items?.accecptedPorposalByFreelancer?.status
                                  }
                                  onClick={() => {
                                    history.push({
                                      pathname: "/project-detail",
                                      state: items?._id,
                                    });
                                  }}
                                />
                              );
                            })}
                          </Carousel>
                        </div>
                      )}
                      {isMobile && projects?.length > 0 && (
                        <div className="projectCard-box">
                          <Swiper spaceBetween={30} slidesPerView={1}>
                            {projects?.map((items, index) => {
                              return (
                                <SwiperSlide>
                                  <ProjectCards
                                    key={items?._id}
                                    title={
                                      items?.accecptedPorposalByFreelancer
                                        ?.title
                                    }
                                    description={
                                      items?.accecptedPorposalByFreelancer
                                        ?.description
                                    }
                                    amount={
                                      items?.accecptedPorposalByFreelancer
                                        ?.amount
                                    }
                                    timeline={
                                      items?.timeline || "Discuss on Chat"
                                    }
                                    projectStatus={
                                      items?.accecptedPorposalByFreelancer
                                        ?.status
                                    }
                                    onClick={() => {
                                      history.push({
                                        pathname: "/project-detail",
                                        state: items?._id,
                                      });
                                    }}
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      )}
                    </Col>
                  ) : (
                    <Col md={6}>
                      {!isMobile && projects?.length > 0 && (
                        <div className="projectCard-box">
                          {projects?.map((items, index) => {
                            return (
                              <ProjectCards
                                key={items?._id}
                                title={
                                  items?.accecptedPorposalByFreelancer?.title
                                }
                                description={
                                  items?.accecptedPorposalByFreelancer
                                    ?.description
                                }
                                amount={
                                  items?.accecptedPorposalByFreelancer?.amount
                                }
                                timeline={items?.timeline || "Discuss on Chat"}
                                projectStatus={
                                  items?.accecptedPorposalByFreelancer?.status
                                }
                                onClick={() => {
                                  history.push({
                                    pathname: "/project-detail",
                                    state: items?._id,
                                  });
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                      {isMobile && projects?.length > 0 && (
                        <div className="projectCard-box c-p">
                          <Swiper spaceBetween={30} slidesPerView={1}>
                            {projects?.map((items, index) => {
                              return (
                                <SwiperSlide>
                                  <ProjectCards
                                    key={items?._id}
                                    title={
                                      items?.accecptedPorposalByFreelancer
                                        ?.title
                                    }
                                    description={
                                      items?.accecptedPorposalByFreelancer
                                        ?.description
                                    }
                                    amount={
                                      items?.accecptedPorposalByFreelancer
                                        ?.amount
                                    }
                                    timeline={
                                      items?.timeline || "Discuss on Chat"
                                    }
                                    projectStatus={
                                      items?.accecptedPorposalByFreelancer
                                        ?.status
                                    }
                                    onClick={() => {
                                      history.push({
                                        pathname: "/project-detail",
                                        state: items?._id,
                                      });
                                    }}
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      )}
                    </Col>
                  )}
                </>
              )}

              {projects.length === 0 && !isApiCall && (
                <div className="p-d-null-data">
                  <NullDataComponent c1 text={"No Project awarded yet."} />
                </div>
              )}
            </Col>
            {/* Invoice */}
            <Col lg={12}>
              <div className="Dashboardpage-invoice">
                <h2>My Earnings</h2>
                {payments?.length > 0 && (
                  <div className="Dashboardpage-invoice-btn">
                    <button
                      onClick={() => {
                        history.push("/payment");
                      }}
                    >
                      View More
                    </button>
                  </div>
                )}
              </div>
              {isApiCall ? (
                <TableRowsLoader />
              ) : (
                <>
                  {payments?.length > 0 && (
                    <Payment payments={payments?.slice(0, 4)} />
                  )}
                </>
              )}

              {payments.length === 0 && !isApiCall && (
                <div className="p-d-null-data">
                  <NullDataComponent c2 text={"No invoice received yet."} />
                </div>
              )}
            </Col>
            {/* new Proposals */}
            <div className="Dashboardpage-project mt-5">
              <h2>New Proposals</h2>
              {newProposals?.length > 0 && (
                <div className="Dashboardpage-project-btn">
                  <button
                    onClick={() => {
                      history.push("/AssignedProject");
                    }}
                  >
                    View More
                  </button>
                </div>
              )}
            </div>
            {isApiCall ? (
              <CardLoader size={400} />
            ) : (
              <>
                {/* My Projects */}
                {newProposals?.length > 1 ? (
                  <Col lg={12}>
                    {!isMobile && newProposals?.length > 0 && (
                      <div className="projectCard-box">
                        <Carousel
                          itemsToScroll={1}
                          pagination={false}
                          breakPoints={breakPoints}
                          itemPadding={[10, 20]}
                        >
                          {newProposals.map((items, index) => {
                            return (
                              <ProjectCards
                                title={items?.title}
                                description={items?.description}
                                amount={items?.amount}
                                timeline={
                                  items?.projectId?.timeline ||
                                  "Discuss on Chat"
                                }
                                projectStatus={items?.projectId?.projectStatus}
                                key={items?.index}
                                onClick={() => {
                                  history.push({
                                    pathname: "/project-detail",
                                    state: items?.projectId?._id,
                                  });
                                }}
                              />
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                    {isMobile && newProposals?.length > 0 && (
                      <div className="projectCard-box">
                        <Swiper spaceBetween={30} slidesPerView={1}>
                          {newProposals.map((items, index) => {
                            return (
                              <SwiperSlide>
                                <ProjectCards
                                  title={items?.title}
                                  description={items?.description}
                                  amount={items?.amount}
                                  timeline={
                                    items?.projectId?.timeline ||
                                    "Discuss on Chat"
                                  }
                                  projectStatus={
                                    items?.projectId?.projectStatus
                                  }
                                  key={items?.index}
                                  onClick={() => {
                                    history.push({
                                      pathname: "/project-detail",
                                      state: items?.projectId?._id,
                                    });
                                  }}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    )}
                  </Col>
                ) : (
                  <Col md={6}>
                    {!isMobile && newProposals?.length > 0 && (
                      <div className="projectCard-box c-p">
                        {newProposals.map((items, index) => {
                          return (
                            <ProjectCards
                              title={items?.title}
                              description={items?.description}
                              amount={items?.amount}
                              timeline={
                                items?.projectId?.timeline || "Discuss on Chat"
                              }
                              projectStatus={items?.projectId?.projectStatus}
                              key={items?.index}
                              onClick={() => {
                                history.push({
                                  pathname: "/project-detail",
                                  state: items?.projectId?._id,
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    )}
                    {isMobile && newProposals?.length > 0 && (
                      <div className="projectCard-box">
                        <Swiper spaceBetween={30} slidesPerView={1}>
                          {newProposals.map((items, index) => {
                            return (
                              <SwiperSlide>
                                <ProjectCards
                                  title={items?.title}
                                  description={items?.description}
                                  amount={items?.amount}
                                  timeline={
                                    items?.projectId?.timeline ||
                                    "Discuss on Chat"
                                  }
                                  projectStatus={
                                    items?.projectId?.projectStatus
                                  }
                                  key={items?.index}
                                  onClick={() => {
                                    history.push({
                                      pathname: "/project-detail",
                                      state: items?.projectId?._id,
                                    });
                                  }}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    )}
                  </Col>
                )}
              </>
            )}

            {newProposals.length === 0 && !isApiCall && (
              <div className="p-d-null-data h-50-imp">
                <NullDataComponent c4 text={"No new proposals received yet."} />
              </div>
            )}
          </Col>
          {/* <Col lg={3}>
              <MessageSideBar />
            </Col> */}
        </Row>
      </Container>
      <Footer />
      {/* </section> */}
      {/* {isApiCall && <BodyLoader />} */}
    </>
  );
}
