import React from "react";
import ReactHtmlParser from "react-html-parser";

const ShowMoreShowLessText = ({ text, visibility = 30, quill = false }) => {
  const [isshowingMore, setIsShowingMore] = React.useState(false);

  return (
    <>
      {quill
        ? (
          <p className="des_x new_x">
             {ReactHtmlParser(text?.substring(0, isshowingMore ? text.length : visibility))}
          </p>
         
          )
        : (text?.substring(0, isshowingMore ? text.length : visibility))}
      {text?.length > visibility && !isshowingMore && "..."}{" "}
      {text?.length > visibility && (
        <span
          className="c-p"
          onClick={() => setIsShowingMore((p) => !p)}
          style={{
            color: "#1CB735",
            fontWeight: "700",
          }}
        >
          {" "}
          {isshowingMore ? "Show Less" : "Show More"}
        </span>
      )}
    </>
  );
};

export default ShowMoreShowLessText;
