import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ModalSkeleton from "../components/ModalSkeleton";

function AreYouSureModal({ open, setOpen, onClick, isLoading, title,modalbox2 = false }) {
  return (
    <ModalSkeleton open={open} setOpen={() => setOpen(false)} maxContent className={`areYourSureModal`} areyousure={true}>
      <section className="are-you-sure-modal">
        <Container>
          <Row>
            <Col lg={12}>
              <div className={`modal-box ${`${modalbox2}` && `modalbox2`}`}>
                <h5>{title}</h5>
                <div className="btns-box">
                  <button
                    className="no-btn"
                    disabled={isLoading}
                    onClick={() => setOpen(false)}
                  >
                    No
                  </button>
                  <button
                    className="yes-btn"
                    disabled={isLoading}
                    onClick={() => onClick()}
                  >
                    {isLoading ? "Wait....." : "Yes"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </ModalSkeleton>
  );
}

export default AreYouSureModal;
