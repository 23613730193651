import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ReactPlayer from "react-player";
import Carousel from "react-elastic-carousel";
import residentailImage from "../assets/images/Mask Group 13.png";
import commercialImage from "../assets/images/Mask Group 14.png";
import civilImage from "../assets/images/Mask Group 15.png";
import residentailImage2 from "../assets/images/Group 577.png";
import commercialImage2 from "../assets/images/Group 578.png";
import civilImage2 from "../assets/images/Group 579.png";
import bannnarImage from "../assets/images/Group 580.png";
import { BsFillClockFill } from "react-icons/bs";
import moment from "moment";

import "../assets/styles/services.css";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../Config/apiUrl";
import { AiFillPlayCircle } from "react-icons/ai";
import ViewMoreModal from "./ViewMoreModal";

function OurNews({ data }) {
  const history = useHistory();

  const [ended, setEnded] = useState(false);
  const [modalData, setModalData] = useState({});

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const openCheck = (imageUrl, title, description) => {
    setOpenDetailModal(true);
    setModalData({ imageUrl, title, description });
  };

  const [isApiCall, setIsApiCall] = useState(false);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
  ];
  return (
    <>
      <section>
        <Container className="latestNew-container mb-5">
          <Row>
            <Col lg={12}>
              <div className="NewsCard-section" data-aos="fade-up">
                <h1>Announcements</h1>
              </div>
            </Col>
          </Row>
          <Carousel
            itemsToScroll={1}
            // pagination={false}
            breakPoints={breakPoints}
            itemPadding={[0, 20]}
            renderPagination={({ pages, activePage, onClick }) => {
              return (
                <Row className="d-flex">
                  {pages.map((page) => {
                    const isActivePage = activePage === page;
                    return (
                      <div
                        onClick={() => onClick(page)}
                        key={page}
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          backgroundColor: isActivePage ? "#ccc" : "#eee",
                        }}
                      />
                    );
                  })}
                </Row>
              );
            }}
          >
            {/* <Row className="latestNew-row"> */}
            {data["announcements"]?.map((item) => {
              return (
                //   <Col lg={4} md={6} sm={6} xs={12} className="latestNews-768" >
                <div
                  className="NewsCard c-p"
                  onClick={() => {
                    if (item?.link !== "" || null) {
                      window.open(`${item?.link}`);
                    }
                  }}
                >
                  <Card>
                    <div className="latestNews-img-box">
                      <Card.Img
                        className="latestNews-img"
                        variant="top"
                        src={`${imageUrl}${item?.image}`}
                      />
                    </div>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          <BsFillClockFill className="clock-icon" />
                        </span>
                        <span className="time">
                          {moment(item?.createdAt).format("MMMM d, Y")}{" "}
                        </span>
                      </Card.Text>
                      <Card.Title className="latestNewsTitle">
                        <h4>{item?.title}</h4>
                      </Card.Title>
                      <Card.Text>
                        <p className="small">{item?.description}</p>
                        <p
                          className="mid c-p"
                          onClick={(e) => {
                            openCheck(
                              item?.image,
                              item?.title,
                              item?.description
                            );
                            {
                              e.stopPropagation();
                            }
                          }}
                        >
                          <span>Read more...</span>
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                //   </Col>
              );
            })}

            {/* <Col lg={12}>
            <div className="NewCardsbtn" data-aos="zoom-out-up">
              <button
              >View More</button>
            </div>
          </Col> */}
            {/* </Row> */}
          </Carousel>
        </Container>
        <ViewMoreModal
          open={openDetailModal}
          setOpen={setOpenDetailModal}
          modalData={modalData}
        />
      </section>
    </>
  );
}

export default OurNews;
