import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function CardLoader({size ,smbox=true}) {
  return (
    <Stack spacing={1}>
      {/* <Skeleton variant="text" /> */}
      {
        smbox && (
          <Skeleton variant="text" height= {`${size-300}px`} />
        )

      }
      <Skeleton variant="rectangular" width="100%" height= {`${size}px`} />
    </Stack>
  );
}