import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsFillStarFill } from "react-icons/bs";
import profileImg1 from "../assets/images/Ellipse 3.png";
import profileImg2 from "../assets/images/Ellipse 2.png";
import profileImg3 from "../assets/images/Ellipse 4.png";
import "../assets/styles/Testimonial.css";
import { imageUrl } from "../Config/apiUrl";
import Rating from "@mui/material/Rating";
export default function Testimonial({ data }) {
  const [testimonials, setTestimonials] = useState(data?.testimonials);
  useEffect(() => {
    setTestimonials(data?.testimonials);
  }, [data]);

  // shuffleData
  let shuffleData = (indexToMove) => {
    if (indexToMove === 1 || indexToMove > testimonials?.length - 1) {
      return;
    }

    let arr = [...testimonials];
    arr[1] = arr[indexToMove];
    arr[indexToMove] = testimonials[1];

    setTestimonials(arr);
  };

  return (
    <>
      <section className="testimonial">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="testimonial-section " data-aos="fade-up">
                <h1>Testimonial</h1>
                <p className="big">
                  {(testimonials?.length > 0 && testimonials[1]?.description) ||
                    (testimonials?.length == 1 && testimonials[0]?.description)}
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="testimonial-section-stars">
                <Rating
                  className="stars"
                  name="read-only"
                  value={
                    (testimonials?.length > 0 && testimonials[1]?.rating) ||
                    (testimonials?.length == 1 && testimonials[0]?.rating)
                  }
                  readOnly
                />
              </div>
            </Col>
            {/* <Col lg={12}> */}
            <span className="testimonail-line"></span>
            <div className="testimonial-section-profileImg">
              <Row>
                {testimonials?.map((e, i) => {
                  return (
                    <>
                      <Col
                        className={i !== 1 ? "coll_one" : "coll_two"}
                        lg={i == 1 ? 2 : 1}
                      >
                        <div
                          className="profileImg"
                          onClick={() => {
                            if (testimonials?.length !== 1) {
                              shuffleData(i);
                            }
                          }}
                        >
                          {testimonials?.length == 1 && (
                            <img
                              src={`${imageUrl}${e?.image}`}
                              alt="profileImg1"
                            />
                          )}
                          {testimonials?.length !== 1 && (
                            <img
                              src={`${imageUrl}${e?.image}`}
                              alt="profileImg1"
                            />
                          )}
                        </div>
                      </Col>
                    </>
                  );
                })}
                {testimonials?.length == 2 && (
                  <Col lg={1} className="coll_one"></Col>
                )}
              </Row>
            </div>
            {/* </Col> */}
            <Col lg={12}>
              <div className="profilenames">
                {(testimonials?.length > 0 && testimonials[1]?.subTitle) ||
                  (testimonials?.length == 1 && testimonials[0]?.subTitle)}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
