import React from "react";
import { useHistory } from "react-router-dom";

import paymentImg from "../assets/images/coin.png";
import projectImg from "../assets/images/company.png";
import invoiceImg from "../assets/images/invoiceIcon.png";
import proposalImg from "../assets/images/business-proposal.png";
import messageImg from "../assets/images/messageIcon.png";
import promoteImg from "../assets/images/promote.png";
import profileImg from "../assets/images/profileIcon.png";
import milestoneImg from "../assets/images/achievement.png";
import { BaseURL } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import moment from "moment";

const NotificationBox = ({ data }) => {
  const history = useHistory();
  let img;

  if (data?.notificationType === "project") img = projectImg;
  if (data?.notificationType === "invoice") img = invoiceImg;
  if (data?.notificationType === "proposal") img = proposalImg;
  if (data?.notificationType === "message") img = messageImg;
  if (data?.notificationType === "promotional") img = promoteImg;
  if (data?.notificationType === "user") img = profileImg;
  if (data?.notificationType === "milestone") img = milestoneImg;
  if (data?.notificationType === "payment") img = paymentImg;

  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  // mark as read
  const markNotiasRead = async (projectId) => {
    if (data?.notificationType === "message") {
      history.push({
        pathname: "/ChatScreen",
        state: {
          userId: data?.sender?._id,
          projectId: projectId,
          roomId: data?.room,
        },
      });
    }
    if (projectId && data?.notificationType !== "message") {
      history.push({
        pathname: user?.role == "customer" ? "/detailPage" : "/project-detail",
        state: projectId,
        search: "loc=4",
      });
    }

    if (data?.isReaded) return;
    const url = BaseURL(`notifications/seen?notificationId=${data?._id}`);
    await Get(url, accessToken, false);
  };

  return (
    <div
      className="Notification-body"
      onClick={() => markNotiasRead(data?.projectId?._id)}
    >
      <div className="Notification-avatar">
        <img src={img} />
        {!data?.isReaded && (
          <div
            style={{
              position: "absolute",
            }}
          >
            🔴
          </div>
        )}
      </div>
      <div className="Notification-text">
        <p className="t-t-c">
          {data?.message.replace(".", "")} for your project titled,{" "}
          <b className="t-t-c">{data?.projectId?.title} </b>
        </p>
        <span>{moment(data?.createdAt).format("lll")}</span>
      </div>
    </div>
  );
};

export default NotificationBox;
