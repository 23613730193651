import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import "../assets//styles/Dashboardpage.css";
import dashboardimg1 from "../assets/images/dashboard.png";
import dashboardimg2 from "../assets/images/Group 550.png";
import dashboardimg3 from "../assets/images/Group 551.png";
import dashboardimg4 from "../assets/images/Group 552.png";
import dashboardimg5 from "../assets/images/Group 553.png";
import dashboardimg6 from "../assets/images/Group 554.png";
import addProject from "../assets/images/collection-add.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BaseURL, getSpecificSearchParam } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import useWindowSize from "../hooks/useWindowSize";

export default ({ setSidebarMainState }) => {
  const loc = getSpecificSearchParam("loc");

  const [width, height] = useWindowSize();
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    width < 992 ? false : true
  );

  useEffect(() => {
    setIsSidebarOpen(width < 992 ? false : true);
  }, [width]);

  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const location = useLocation().pathname;
  const role = user?.role;
  const history = useHistory();

  // get noti Count
  const [notiCount, setNotiCount] = useState(0);
  const [messageCount, setMessagesCount] = useState(0);
  const getNotiAndMessagescount = async () => {
    const url = BaseURL("notifications/all?limit=1&page=1");
    const url2 = BaseURL("notifications/all?limit=1&page=1&isMessage=true");
    const [response, response2] = await Promise.all([
      Get(url, accessToken, false),
      Get(url2, accessToken, false),
    ]);
    if (response !== undefined) {
      setNotiCount(response.data?.newNotifications);
    }
    if (response2 !== undefined) {
      setMessagesCount(response2.data?.newNotifications);
    }
  };

  useEffect(() => {
    getNotiAndMessagescount();
  }, [window.location.pathname]);

  // sidebar
  useEffect(() => {
    setSidebarMainState && setSidebarMainState(isSidebarOpen);
  }, [isSidebarOpen]);

  return (
    <>
      <section
        className={`Dashboardpage-section  sideNavBar ${
          !isSidebarOpen && "Dashboardpage-section-open-x"
        }`}
      >
        <div className="Dashboardpage-links ">
          <ul>
            <li
              className={`btn custom-btn ${
                location === "/dashboard" && "active-link active-icon"
              }`}
              onClick={() => {
                if (!accessToken) return;
                history.push("/dashboard");
              }}
            >
              {" "}
              <span>
                <img src={dashboardimg1} alt="dashboardimg" />
              </span>{" "}
              {isSidebarOpen && "Dashboard"}
            </li>
            {role == "customer" && (
              <li
                className={`btn custom-btn ${
                  location === "/add-project" && "active-link active-icon"
                }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/add-project");
                }}
              >
                {" "}
                <span className="icon-img">
                  <img src={addProject} alt="dashboardimg" />
                </span>
                {isSidebarOpen && "Add Project"}
              </li>
            )}
            {role == "customer" && (
              <li
                className={`btn custom-btn ${
                  location === "/myproject" && "active-link active-icon"
                }
                 ${loc == 1 && "loc-activated"}
                `}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/myproject");
                }}
              >
                {" "}
                <span className="icon-img">
                  <img src={dashboardimg2} alt="dashboardimg" />
                </span>
                {isSidebarOpen && " My Projects"}
              </li>
            )}
            {role == "customer" && (
              <li
                className={`btn custom-btn ${
                  location === "/Invoice" && "active-link active-icon"
                } ${loc == 2 && "loc-activated"}`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/Invoice");
                }}
              >
                {" "}
                <span className="icon-img">
                  <img src={dashboardimg3} alt="dashboardimg" />
                </span>
                {isSidebarOpen && "Invoices"}
              </li>
            )}

            {role == "freelancer" && (
              <li
                className={`btn custom-btn ${
                  location === "/AssignedProject" && "active-link active-icon"
                } ${loc == 1 && "loc-activated"}`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/AssignedProject");
                }}
              >
                <span>
                  <img src={dashboardimg3} alt="dashboardimg" />
                </span>
                {isSidebarOpen && "Assigned Projects"}
              </li>
            )}
            {(role == "freelancer" || role == "customer") && (
              <li
                className={`btn custom-btn ${
                  location === "/payment" && "active-link active-icon"
                } ${loc == 3 && "loc-activated"}`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/payment");
                }}
              >
                <span>
                  <img src={dashboardimg4} alt="dashboardimg" />
                </span>
                {isSidebarOpen && "Payments"}
              </li>
            )}

            {(role == "freelancer" || role == "customer") && (
              <li
                className={`btn custom-btn ${
                  location === "/notification" && "active-link active-icon"
                } ${loc == 4 && "loc-activated"}`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/notification");
                }}
              >
                <span>
                  <img src={dashboardimg6} alt="dashboardimg" />
                </span>
                {/* Notification{notiCount > 0 && "s"}{" "}
                {notiCount > 0 ? `(${notiCount})` : ""} */}
                {isSidebarOpen &&
                  `Notification${notiCount > 0 ? "s" : ""} ${
                    notiCount > 0 ? `(${notiCount})` : ""
                  }`}
              </li>
            )}
            {(role == "freelancer" || role == "customer") && (
              <li
                className={`btn custom-btn ${
                  location === "/chat-notifications" &&
                  "active-link active-icon"
                }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/chat-notifications");
                }}
              >
                <span>
                  <img src={dashboardimg6} alt="dashboardimg" />
                </span>
                {/* Notification{notiCount > 0 && "s"}{" "}
                {notiCount > 0 ? `(${notiCount})` : ""} */}
                {isSidebarOpen &&
                  `Chat Alert${messageCount > 0 ? "s" : ""} ${
                    messageCount > 0 ? `(${messageCount})` : ""
                  }`}
              </li>
            )}

            <span
              className="collapseIcon c-p"
              onClick={() => setIsSidebarOpen((p) => !p)}
            >
              {isSidebarOpen ? (
                <MdOutlineKeyboardArrowRight />
              ) : (
                <MdOutlineKeyboardArrowLeft />
              )}
            </span>
          </ul>
        </div>
        {/* </Col> */}
      </section>
    </>
  );
};
