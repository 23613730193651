import React from "react";
import { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, validateEmail } from "../Config/apiUrl";
import { toast } from "react-toastify";
import "../assets/styles/signInpage.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoginUserData } from "../store/Actions/authAction";
import Header from "../components/header.jsx";
import Footer from "../components/Footer";
import ForgotPasswordModal from "../components/ForgotPasswordModal";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import DemoAccountLoginCredentials from "../components/DemoAccountLoginCredentials/DemoAccountLoginCredentials.js";

export default function SignInPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);

  async function login() {
    const url = BaseURL("users/login");
    const params = {
      email: email,
      password: password,
    };

    // validate email and password
    for (let key in params)
      if (params[key] === "") return toast.error(`${key} is required`);

    // validate email
    if (!validateEmail(email))
      return toast.error("Please enter a valid email address");

    if (password.length < 8) {
      return toast.error("Password must be at least 8 characters long");
    }

    setIsApiCall(true);
    const response = await Post(url, params, apiHeader());
    setIsApiCall(false);
    if (response !== undefined) {
      dispatch(saveLoginUserData(response.data));
      history.push("/dashboard");
    }
  }

  return (
    <>
      <Header />
      <section className="SignInpage-section">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="SignInpage-section1">
                <div className="SignInpage-Card">
                  <h1>Sign In</h1>
                  <p className="mid my-3">
                    Don't miss your next opportunity. <br />
                    Sign In and stay ahead of the game
                  </p>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        className="input-box siginInputbox"
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="p-r">
                        <Form.Control
                          className="input-box siginInputbox"
                          type={isPasswordVisible ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="password-eye"
                          onClick={() => setIsPasswordVisible((p) => !p)}
                        >
                          {isPasswordVisible ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </span>
                      </div>
                      <div className="check">
                        <span
                          className="big green c-p"
                          onClick={() => setIsForgotPasswordModalOpen(true)}
                        >
                          Forgot Password?
                        </span>
                      </div>
                    </Form.Group>
                  </Form>
                  <div className="SignInpage-btn">
                    <button onClick={login} disabled={isApiCall}>
                      {isApiCall ? "Please Wait..." : "Sign In"}
                    </button>
                  </div>
                  <p className="big">
                    Don't have an account?{" "}
                    <span
                      className="c-p"
                      onClick={() => history.push("/signup")}
                    >
                      Sign Up
                    </span>{" "}
                  </p>
                </div>

                <DemoAccountLoginCredentials />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />

      <ForgotPasswordModal
        showModal={isForgotPasswordModalOpen}
        closeModal={() => setIsForgotPasswordModalOpen(false)}
      />
    </>
  );
}
