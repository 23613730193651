import React, { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";


export default function AddNewCard({
  isApiCall,
  closeModal,
  HandleAttachPM,
  setApiLoading
}) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    setApiLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    setApiLoading(false);
      return;
    } else {
     await HandleAttachPM(paymentMethod?.id);
      toast.success("Card Added successfully", { position: "top-right" });
      closeModal();
    }
  };

  return (
    <>

        <div className="add-payment-method-modal">
          <h5 className="mt-0">Enter Card Detail</h5>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="field-box">
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                  hidePostalCode: true,
                }}
              />
            </div>
            <button
              
              className = "custom-btn"
              disabled={isApiCall}
              type="submit"
              onClick = {()=> closeModal()}
            >
             Back
            </button>
            <button
             
              className="custom-btn ms-2"
               disabled={isApiCall}
              type="submit"
            >
              {isApiCall ? "Adding..." : "Confirm"}
            </button>
          </form>
        </div>
    </>
  );
}
