import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineFileAdd } from "react-icons/ai";

import classes from "./MultiFileUpload.module.css";

function MultiFileUpload({ type = "mix", max = 20, onchange }) {
  let imageType = {
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
  };
  let fileType = {
    accept: {
      "application/pdf": [],
      "application/msword": [],
    },
  };

  let inputType = type === "imageType" ? imageType : fileType;
  let brief =
    type === "imageType"
      ? "Only *.jpeg and *.png images will be accepted."
      : "Only *.pdf files will be accepted.";

  if (type === "mix") {
    inputType = {
      accept: {
        "application/pdf": [],
        "application/msword": [],
        "image/jpeg": [],
        "image/jpg": [],
        "image/png": [],
      },
    };

    brief = "Only *.jpeg and *.png images and *.pdf will be accepted.";
  }


  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    ...inputType,
    maxFiles: max,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {imageTypes?.includes(file?.type) && (
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        )}
        {!imageTypes?.includes(file?.type) && (
          <div className={classes?.fileTypeContainerPreview}>
            <AiOutlineFileAdd />
            <p className="maxLine2">{file?.name}</p>
          </div>
        )}
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    onchange(files);
  }, [files]);

  return (
    <section className={classes?.container}>
      <div {...getRootProps({ className: classes?.dropzone })}>
        <input {...getInputProps()} />
        <p>Drag & drop some files here, or click to select files</p>
        <em>{brief}</em>
        <em>Max {max} files can be added.</em>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}

export default MultiFileUpload;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
