import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { Get } from "../Axios/AxiosFunctions";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import { BaseURL } from "../Config/apiUrl";
import BodyLoader from "../components/BodyLoader";
import Header from "../components/header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  const [loading, setIsLoading] = useState(false);
  const [supportData, setSupportData] = useState(null);
  
  const getData = async () => {
    const url = BaseURL(`cms/page/privacy_policy`);
    setIsLoading(true);
    const response = await Get(url);
    setIsLoading(false);

    if (response !== undefined) {
      setSupportData(response?.data?.data);
    }
  };


  useEffect(() => {
    getData();
  }, []);

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header lg />
      <div className="support_mainContainer">
        <div className="support_Head">
          {/* <h1>Privacy Policy </h1> */}
          {supportData !== null && <p> {parse(supportData?.description1)} </p>}
        </div>

        <div className="support_body">
          <Container>
            <Col>
              {supportData !== null && (
                <div className="support_Div">
                  {parse(supportData?.description2)}
                </div>
              )}
            </Col>
          </Container>
        </div>

        <Footer />

        {loading && <BodyLoader />}
      </div>
    </>
  );
};

export default PrivacyPolicy;
