import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../assets/styles/onboardingprocess.css";
import interviewimg from "../assets/images/Group 575.png";
import Exam from "../assets/images/Group 574.png";
import lineimg from "../assets/images/Path 498.png";
import Account from "../assets/images/Group 576.png";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../Config/apiUrl";

function Onboardingprocess({ data }) {
  const history = useHistory();
  let cardData = [
    {
      coverImg: interviewimg,
      cardHeading: "Interview",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: Exam,
      cardHeading: "Exam",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: Account,
      cardHeading: "Account",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
  ];

  return (
    <>
      <section className="onboardprocess" id="onboard-process">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="onboardprocess-section" data-aos="fade-up">
                <h1>{data.sec2Heading}</h1>
                <p className="mid">{data.sec2Description}</p>
                <div className="line-img">
                  <img src={lineimg} alt="img" />
                </div>
              </div>
            </Col>
            <Row className="onboard-card-row g-0">
            {data["onboarding-process"]?.map((item) => {
              return (
                <Col lg={4} md={6} sm={12}>
                  <div className="onboard-card">
                    <div className="onboard-card-img-box">
                      <img src={`${imageUrl}${item?.image}`} alt="img1" />
                    </div>
                    <h3>{item?.title}</h3>
                    <p className="mid">{item?.description}</p>
                  </div>
                </Col>
              );
            })}
            </Row>
            <Col lg={12}>
              <div className="onboardbtn" data-aos="zoom-out-up">
                <button onClick={() => history.push("/add-project")}>
                  Work With Us
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Onboardingprocess;
