import React, { useState } from "react";

import ModalSkeleton from "./ModalSkeleton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import EmailIcon from "@mui/icons-material/Email";
import { BaseURL, imageUrl, validateEmail } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import { AiFillCloseCircle } from "react-icons/ai";
import ShowMoreShowLessText from "./ShowMoreShowLessText";

const ViewMoreModal = ({
  open,
  setOpen,
  label,
  reasonFun,
  //   message,
  //   handleYes,
  isLoading,
  modalData,
}) => {
  const [reason, setReason] = useState("");

  const func = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <div className="viewMore-container">
    <ModalSkeleton open={open} setOpen={setOpen}>
        {/* <form className="login100-form validate-form"> */}
          {/* <span className="login100-form-title are-you-sure-label">
            {label}
          </span> */}
          <div className="view-more-img-div">
            <img
              src={`${imageUrl}${modalData?.imageUrl}`}
              alt=""
              // width={"100%"}
              // style={{ height: "400px", objectFit: "contain" }}
            />
          </div>
          <h5 className="mt-5 txt">{modalData?.title}</h5>
          <p>
            <ShowMoreShowLessText text={modalData?.description} visibility={100} />
          </p>
        {/* </form> */}
    </ModalSkeleton>
    </div>
  );
};

export default ViewMoreModal;
