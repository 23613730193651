import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import section2_image from "../assets/images/Mask Group 6 (2).png";
import aos from "aos";
import "../assets/styles/HeroSection.css";
import { imageUrl } from "../Config/apiUrl";
import { useHistory } from "react-router-dom";
import { BsMouse } from "react-icons/bs";

function HeroSection({ data }) {
  const history = useHistory();
  useEffect(() => {
    aos.init({
      offset: 120,
      delay: 10,
      duration: 1000,
      easing: "ease-in-out",
      mirror: "true",
      once: "false",
      placement: "top-center",
    });
  }, []);
  // background-image:url(${imageUrl+data?.sec1CoverImage})
  return (
    <>
      <style>{`.HeroSection {
        background:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(${
          imageUrl + data?.sec1CoverImage
        });
        background-size: cover;
    background-position: bottom ; 
    background-repeat: no-repeat;
    width: 100%;
    }`}</style>
      <section className="HeroSection">
        <Container className="higheststandard-con">
          <Row className="higheststandard-row">
            <Col lg={6} md={12} className="higheststandard-col">
              <div className="higheststandard" data-aos="fade-right">
                <h1>{data?.sec1Heading}</h1>
                <p className="mid">{data?.sec1Description}</p>
                <button
                  className="higheststandard-btn1"
                  onClick={() => history.push("/signup")}
                >
                  Get Started
                </button>
              </div>
            </Col>
            <Col lg={6} md={12} className="higheststandard-col2">
              <div className="higheststandard-section2" data-aos="fade-left">
                <img src={`${imageUrl}${data?.sec1Image}`} alt="sec_img" />
              </div>
            </Col>
            {/* <Col lg={12}>
              <div className="ScrollIcon-div ScrollIcon">
                <a href="#onboard-process">
                  <BsMouse className="ScrollIcon" />
                </a>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
}
export default HeroSection;
