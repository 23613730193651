import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import "../assets//styles/Dashboardpage.css";
import invoiceimg from "../assets/images/invoice.png";
import logotakeofbill from "../assets/images/Group 1225 (1).png";
import lineicon from "../assets/images/Path 1553.png";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, recordsLimit } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SideNavBar from "../components/SideNavBar";

import { FaFilePdf } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import MessageSideBar from "../components/MessageSideBar";
import ProjectCards from "../components/ProjectCards";
import Invoice from "../components/Invoice";
import Header from "../components/header.jsx";
import NullDataComponent from "../components/NullDataComponent";
import BodyLoader from "../components/BodyLoader";
import PaginationB from "../components/PaginationB";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import TableRowsLoader from "../components/TableRowsLoader";

export default function CustomerDashboard() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [newProposals, setNewProposals] = useState([]);
  const [payments, setPayments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // get Data
  async function getData(_page) {
    const url = BaseURL(`customer/invoice?limit=${recordsLimit}&page=${_page}`);

    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setPayments(data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <>
      <Header lg />
      {/* <section className="Dashboardpage-section"> */}
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col lg={9}>
            <div className="Dashboardpage-invoice ">
              <h2>Invoices</h2>
            </div>
            {isApiCall ? (
              <TableRowsLoader />
            ) : (
              <>
                {payments?.length > 0 && (
                  <Invoice payments={payments} search={"loc=2"} />
                )}
              </>
            )}

            {totalCount > 1 && (
              <PaginationB
                page={page}
                setPage={setPage}
                totalCount={totalCount}
              />
            )}

            {payments.length === 0 && !isApiCall && (
              <div className="p-d-null-data">
                <NullDataComponent c2 text={"No Invoices Yet."} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
      {/* {isApiCall && <BodyLoader />} */}
    </>
  );
}
