import { LOGIN_SUCCESS, LOGOUT_REQUEST, UPDATE_USER } from "./actionType";

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: response };
}

export function signOutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}


export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    user,
  };
};
