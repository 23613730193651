import HeroSection from "../components/HeroSection";
import BrandsLogo from "../components/BrandsLogo";
import Onboardingprocess from "../components/Onboardingprocess";
import Services from "../components/Services";
import HowToOrder from "../components/HowToOrder";
import Testimonial from "../components/Testimonial";
import LatestNew from "../components/LatestNews";
import ContactUs from "../components/Contact";
import Header from "../components/header";
import Footer from "../components/Footer";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import React, { useState, useEffect } from "react";
import BodyLoader from "../components/BodyLoader";
import OurNews from './../components/OurNews';

function Homepage() {
  const [isApiCall, setIsApiCall] = useState(false);
  const [apiData, setApiData] = useState({});

  async function getData() {
    const url = BaseURL(
      `cms/page/home?crudsArry=our-services&crudsArry=how-to-order&crudsArry=testimonials&crudsArry=our-latest-news&crudsArry=onboarding-process&crudsArry=testimonials&crudsArry=company`
    );
    setIsApiCall(true);
    const response = await Get(url, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setApiData(data);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
    <div className={isApiCall && 'op-0'}>
      <Header />
      <HeroSection data={apiData} />
      <BrandsLogo data={apiData} />
      <Onboardingprocess data={apiData} />
      <Services data={apiData} />
      <OurNews data={apiData} />
      <Testimonial data={apiData} />
      <LatestNew data={apiData} />
      {/* <ContactUs data={apiData} /> */}
      <Footer data={apiData} />
    </div>
   
    </>
  );
}

export default Homepage;
