import { useState } from "react";
import { Container, Row, Col, Form, button } from "react-bootstrap";
import { Post } from "../Axios/AxiosFunctions";
import {
  apiHeader,
  BaseURL,
  exceptThisSymbols,
  formRegEx,
  formRegExReplacer,
  validateEmail,
} from "../Config/apiUrl";
import { toast } from "react-toastify";
import "../assets/styles/SignUppage.css";
import Header from "../components/header.jsx";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";
import { AiFillLock } from "react-icons/ai";
import { HiInformationCircle } from "react-icons/hi";

import ImageSelector from "../components/ImageSelector";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Tooltip } from "@mui/material";

export default function SignUpPage() {
  const history = useHistory();
  const [role, setRole] = useState("freelancer");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [deviceId, setDeviceId] = useState("122323434");
  const [phoneNo, setPhoneNo] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);

  // Client new fields
  const [company, setCompany] = useState("");
  const [job, setJob] = useState("");
  const [location, setLocation] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  //freelancer new fields
  const [age, setAge] = useState("");
  const [qualification, setQualification] = useState("");

  // Pdf Selectors states and fn
  // const [pdfs, setPdfs] = useState([{}, {}, {}]);
  const [pdfs1, setPdfs1] = useState(null);
  const [pdfs2, setPdfs2] = useState(null);
  const [pdfs3, setPdfs3] = useState(null);
  const handlePdfsSubmit = (data) => {
    setPdfs1(data);
  };

  const [isApicall, setIsApicall] = useState(false);

  const [isAgreeToTermsAndConditions, setIsAgreeToTermsAndConditions] =
    useState(false);

  // signup
  async function signUp() {
    const url = BaseURL("users/signup");
    const params = {
      role: role,
      email: email,
      name: name,
      password: password,
      passwordConfirm: passwordConfirm,
      contactNo: phoneNo.replaceAll(" ", "").replaceAll("-", ""),
      ...(role === "freelancer" && { bankDetails, age, qualification }),
      ...(role === "customer" && { company }),
      ...(role === "customer" && { job }),
      ...(role === "customer" && { address: location }),
      ...(role === "customer" && { companyWebsite }),
    };

    // validate data
    for (let key in params)
      if (params[key] === "")
        return toast.error(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    if (!validateEmail)
      return toast.error("Please enter a valid email address");

    if (password.length < 8)
      return toast.error("Password must be at least 8 characters long");

    if (password !== passwordConfirm)
      return toast.error("Password and Confirm Password must match");

    if (
      !validator.isMobilePhone(phoneNo.replaceAll(" ", "").replaceAll("-", ""))
    )
      return toast.warn("Your mobile number is not valid.");

    //  pdfs
    if (role === "freelancer") {
      // const selectedPdfs = pdfs1?.some(
      //   (e) => ![undefined, null, ""].includes(e?.name)
      // );
      if (![!!pdfs1, !!pdfs2, !!pdfs3].includes(true))
        return toast.warn(
          "Please upload atleast 1 document for identification."
        );
    }
    if (!isAgreeToTermsAndConditions)
      return toast.error("Please agree to terms and conditions");
    params.deviceId = deviceId;

    // create formData
    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }
    if (role === "freelancer") {
      // handle empty initial object
      if (pdfs1?.name) formData.append("identityDoc", pdfs1);
      if (pdfs2?.name) formData.append("addressDoc", pdfs1);
      if (pdfs3?.name) formData.append("qualificationDoc", pdfs1);
    }

    setIsApicall(true);
    const response = await Post(url, formData, apiHeader(false, true));
    setIsApicall(false);
    if (response !== undefined) {
      toast.success(
        "Please check your email. We have sent you a verification link."
      );
      history.push("/login");
    }
  }

  return (
    <>
      <Header />
      <section className="SignUppage-section">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="SignUppage-section1">
                <div className="SignUppage-Card">
                  <h1>Sign Up</h1>
                  <p className="mid my-4">
                    Make the most of opportunities, signup for accurate
                    measurement
                  </p>
                  <div className="role-btns">
                    <button
                      className={`${
                        role === "freelancer" ? "role-btn-selected" : "role-btn"
                      }`}
                      onClick={() => setRole("freelancer")}
                    >
                      Consultant
                    </button>
                    <button
                      className={`${
                        role === "customer" ? "role-btn-selected" : "role-btn"
                      }`}
                      onClick={() => setRole("customer")}
                    >
                      Client
                    </button>
                  </div>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      {/* <Form.Label ></Form.Label> */}
                      <Form.Control
                        className="input-box siginInputbox"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {role === "customer" && (
                        <>
                          <Form.Control
                            className="input-box siginInputbox"
                            type="text"
                            placeholder="Company Name"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                          <Form.Control
                            className="input-box siginInputbox"
                            type="text"
                            placeholder="Job | Position"
                            value={job}
                            onChange={(e) => setJob(e.target.value)}
                          />
                          <Form.Control
                            className="input-box siginInputbox"
                            type="text"
                            placeholder="Location | Address"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                          <Form.Control
                            className="input-box siginInputbox"
                            type="text"
                            placeholder="Company Website"
                            value={companyWebsite}
                            onChange={(e) => setCompanyWebsite(e.target.value)}
                          />
                        </>
                      )}
                      {/* <Form.Label ></Form.Label> */}
                      <Form.Control
                        className="input-box siginInputbox"
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <ReactPhoneInput
                        containerClass="phone-container signupScreen"
                        enableSearchField
                        value={phoneNo}
                        onChange={(phone) => setPhoneNo(phone)}
                      />
                      {/* <Form.Control
                        className="input-box siginInputbox"
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                      /> */}
                      {/* <Form.Label ></Form.Label> */}
                      <div className="p-r">
                        <Form.Control
                          className="input-box siginInputbox"
                          type={isPasswordVisible ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="password-eye"
                          onClick={() => setIsPasswordVisible((p) => !p)}
                        >
                          {isPasswordVisible ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </span>
                      </div>
                      {/* <Form.Label></Form.Label> */}
                      <div className="p-r">
                        <Form.Control
                          className="input-box siginInputbox"
                          type={isRePasswordVisible ? "text" : "password"}
                          placeholder="Confirm Password"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                        <span
                          className="password-eye"
                          onClick={() => setIsRePasswordVisible((p) => !p)}
                        >
                          {isRePasswordVisible ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </span>
                      </div>
                      {role === "freelancer" && (
                        <>
                          <Form.Control
                            className="input-box siginInputbox"
                            type="number"
                            placeholder="Age"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <Form.Control
                            className="input-box siginInputbox"
                            type="text"
                            placeholder="Qualification"
                            value={qualification}
                            onChange={(e) => setQualification(e.target.value)}
                          />
                          <div className="p-r">
                            <Form.Control
                              as="textarea"
                              className="bank-detail-area secure-input mt-3"
                              type="text"
                              placeholder="Bank Details"
                              value={bankDetails}
                              onChange={(e) => setBankDetails(e.target.value)}
                              rows={3}
                            />
                            <AiFillLock className="secureInputLock" />
                            <div className="payment-info payment-info-container">
                              <Tooltip
                                placement="left"
                                title="Add your payment details i.e. your IBAN number | Bank account number | Paypal etc.  Information here would be Bank Name, Bank Address, bank AC Number, Sort Code and BIC"
                              >
                                <span>
                                  <HiInformationCircle />
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          <Col lg={12} md={12}>
                            <div
                              style={{ margin: 0 }}
                              className="image-multi-container"
                            >
                              {/* {pdfs.map((item, index) => ( */}
                              <>
                                <div className="img_compo_head c-p">
                                  <small>
                                    Upload a document to verify your identity
                                  </small>
                                  <ImageSelector
                                    index={null}
                                    item={pdfs1}
                                    onSubmit={(data) => setPdfs1(data)}
                                    initialState={pdfs1}
                                    fileTypeArray={[
                                      "application/pdf",
                                      "application/msword",
                                    ]}
                                    fileType="pdf"
                                    selectorParaStyles={{
                                      height: "100%",
                                    }}
                                  />
                                  <small>
                                    Upload a document to verify your address
                                  </small>
                                  <ImageSelector
                                    index={null}
                                    item={pdfs2}
                                    onSubmit={(data) => setPdfs2(data)}
                                    initialState={pdfs2}
                                    fileTypeArray={[
                                      "application/pdf",
                                      "application/msword",
                                    ]}
                                    fileType="pdf"
                                    selectorParaStyles={{
                                      height: "100%",
                                    }}
                                  />
                                  <small>
                                    Upload a document to verify your
                                    qualifications as a Consultant
                                  </small>
                                  <ImageSelector
                                    index={null}
                                    item={pdfs3}
                                    onSubmit={(data) => setPdfs3(data)}
                                    initialState={pdfs3}
                                    fileTypeArray={[
                                      "application/pdf",
                                      "application/msword",
                                    ]}
                                    fileType="pdf"
                                    selectorParaStyles={{
                                      height: "100%",
                                    }}
                                  />
                                </div>
                              </>
                              {/* ))} */}
                              {/* <ImageSelector
                                onSubmit={handlePdfsSubmit}
                                initialState={pdfs}
                                isAddEmpty={true}
                              /> */}
                            </div>
                          </Col>
                        </>
                      )}
                      <div className="SignUp-check">
                        <Form.Check
                          inline
                          label="I agree"
                          name="I-agree"
                          type="checkbox"
                          isValid
                          value={isAgreeToTermsAndConditions}
                          onChange={() =>
                            setIsAgreeToTermsAndConditions((p) => !p)
                          }
                        />
                        <span
                          className="big c-p f19px"
                          onClick={() => window.open("/support")}
                        >
                          Terms & CondItions
                        </span>
                      </div>
                    </Form.Group>
                  </Form>
                  <div className="SignUppage-btn">
                    <button onClick={signUp} disabled={isApicall}>
                      {isApicall ? "Please Wait..." : "Sign Up"}
                    </button>
                  </div>
                  <p className="big">
                    Already have account?{" "}
                    <span
                      className="c-p"
                      onClick={() => history.push("/login")}
                    >
                      Sign In
                    </span>{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
