import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import ModalSkeleton from "./ModalSkeleton";

function ProposalRejectReasonModal({
  open,
  setOpen,
  onClick,
  isLoading,
  modalbox2 = false,
}) {
  const [reason, setReason] = useState("");

  return (
    <ModalSkeleton
      open={open}
      setOpen={() => setOpen(false)}
      maxContent
      className={`areYourSureModal`}
      rejection={true}
    >
      <section className="are-you-sure-modal">
        <Container>
          <Row>
            <textarea
              rows={5}
              placeholder="Please tell us why you are rejecting this proposal."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="form-control mt-3"
            />
            <Col lg={12}>
              <div className={`modal-box ${`${modalbox2}` && `modalbox2`}`}>
                <div className="btns-box">
                  <button
                    className="no-btn"
                    disabled={isLoading}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="yes-btn"
                    disabled={isLoading}
                    onClick={() => {
                      if (reason.trim() === "")
                        return toast.warn("Rejection reason is required.");

                      onClick(reason);
                    }}
                  >
                    {isLoading ? "Wait....." : "Submit"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </ModalSkeleton>
  );
}

export default ProposalRejectReasonModal;
