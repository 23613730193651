import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { apiHeader, BaseURL, numberWithCommas } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import PaymentMethodList from "../components/PaymentMethodList";
import AddNewCard from "./AddNewCard";
import { toast } from "react-toastify";
import { currencies } from "../pages/currencies";
var numeral = require("numeral");
export default function AcceptProposalModal({
  showModal,
  closeModal,
  amount,
  payMileStone,
  isLoading,
  project,
}) {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  let getPaymentMethodApiUrl = BaseURL("users/payment-methods");
  let attachPMApiUrl = BaseURL("users/attach-payment-methods");
  let detachPMApiUrl = BaseURL("users/detach-payment-methods");

  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isApiCall, setIsApiCall] = useState(false);

  const [showAddCard, setShowAddCard] = useState(false);
  var numeral = require("numeral");
  const [selectedId, setSelectedId] = useState();
  const Header = apiHeader(accessToken);

  useEffect(() => {
    getPaymentMethodList();
  }, []);

  const getPaymentMethodList = async () => {
    setLoading(true);

    let requestResponse = await Get(getPaymentMethodApiUrl, accessToken);
    if (requestResponse !== undefined) {
      setPaymentMethodList(requestResponse?.data?.data);
    }
    setLoading(false);
  };
  const HandleAttachCard = async (id) => {
    setIsApiCall(true);
    const response = await Post(attachPMApiUrl, { pmId: id }, Header);
    if (response !== undefined) {
      setPaymentMethodList(response?.data?.data);
    }
    setIsApiCall(false);
  };

  const HandleDetachCard = async (id, closeModal) => {
    setLoading(true);
    const response = await Post(detachPMApiUrl, { pmId: id }, Header);
    if (response !== undefined) {
      setPaymentMethodList(response?.data?.data);
      toast.success("Card Deleted successfully", { position: "top-right" });
      setSelectedId("");
      closeModal();
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowAddCard(false);
          closeModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {showAddCard ? "Add New Card" : "Pay For MileStone"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAddCard ? (
            <AddNewCard
              setApiLoading={setIsApiCall}
              isApiCall={isApiCall}
              closeModal={() => {
                setShowAddCard(!showAddCard);
              }}
              HandleAttachPM={HandleAttachCard}
            />
          ) : (
            <PaymentMethodList
              HandleShowAddNewCard={() => {
                setShowAddCard(!showAddCard);
              }}
              PaymentMethodList={paymentMethodList}
              HandleDetachCard={HandleDetachCard}
              loading={loading}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
            />
          )}
        </Modal.Body>
        {!showAddCard && (
          <Modal.Footer>
            <div className="btn-x">
              <button
                className="btn-detail"
                disabled={!selectedId || isLoading}
                onClick={() => payMileStone(selectedId)}
              >
                {isLoading
                  ? "Please Wait..."
                  : `Pay ${
                      project?.currency &&
                      currencies[project?.currency?.toUpperCase()].symbol
                    }${numberWithCommas(amount)}`}
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
