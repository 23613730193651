export function isMobileViewHook(setIsMobile, maxWidth= 421) {
    window.addEventListener("load", () => {
      getWidthAndDecideScreen(setIsMobile, maxWidth);
    });
    window.addEventListener("resize", () => {
      getWidthAndDecideScreen(setIsMobile, maxWidth);
    });
    getWidthAndDecideScreen(setIsMobile, maxWidth);
  }
  
  function getWidthAndDecideScreen(setIsMobile, maxWidth) {
    if (window.innerWidth < maxWidth) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }