import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PagesSection1 from "../components/PagesSection1";
import HowToOrder from "../components/HowToOrder";
import Contact from "../components/Contact";
import Relaxman from "../assets/images/Relax Man.png";
import "../assets/styles/HowToOrderPage.css";
import Header from "../components/header";
import Footer from "../components/Footer";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl } from "../Config/apiUrl";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
export default function HowToOrderpage() {
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [apiData, setApiData] = useState({});

  async function getData() {
    const url = BaseURL(`cms/page/order?crudsArry=how-to-order`);
    setIsApiCall(true);
    const response = await Get(url, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setApiData(data);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, []);

  return (
    <div
    // className={isApiCall && 'op-0'}
    >
      <Header />
      <PagesSection1 data={apiData} />
      <HowToOrder data={apiData} />
      <section className="HowToOrderpage-section3 ">
        <Container>
          <Row className="relax-man-row">
            <Col className="relax-man-col1" lg={6} md={{ span: 12, oredr: 2 }}>
              <div className="HowToOrderpage-section3-leftside">
                <h1>{apiData?.sec2Heading}</h1>
                <p className="mid">{apiData?.sec2Description}</p>
                <p className="mid">
                  So, what are you waiting for – outsource to Demo today.
                </p>
                <div className="HowToOrderpage-btns">
                  <button onClick={() => history.push("/signup")}>
                    Get Started
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={6} md={{ span: 12, oredr: 1 }}>
              <div className="HowToOrderpage-section4-rightside relax-man-img-box">
                <img
                  src={imageUrl + apiData?.sec3Image}
                  alt="sectionImage"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}
