import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
  numberWithCommas,
} from "../Config/apiUrl";
import SideNavBar from "../components/SideNavBar";
import { currencies } from "../pages/currencies";
import moment from "moment";
import CardLoader from "./CardLoader";
import { Markup } from "interweave";

var numeral = require("numeral");
function ProjectCards({
  title,
  description,
  amount,
  timeline,
  projectStatus,
  onClick,
  currency = "eur",
  cus_chat,
  isApiCall,
  createDate,
}) {
  const history = useHistory();
  const user = useSelector((state) => state.authReducer.user);
  // console.log(isApiCall )
  return (
    <>
      {
        // isApiCall ? <CardLoader size={400} /> : (
        <div className="mid project-card c-p" onClick={onClick}>
          <h5 className="t-t-c">{title}</h5>
          <p className={`discript_heading`}>Description:</p>
          <p
            className={`mid description des_x ${
              cus_chat && "descriptionShort"
            }`}
          >
            {ReactHtmlParser(description)}
          </p>
          <p className="mid cardPara">
            <span>Budget: </span>
            {currencies[currency.toUpperCase()].symbol}

            <span className="price">
              {/* {numeral(amount).format("0.0a")} */}
              {numberWithCommas(amount)}
            </span>
          </p>
          <p className="mid cardPara">
            <span>Date Due: </span>
            <span className="price">{timeline}</span>
          </p>
          <p className="mid cardPara">
            <span>Submitted On: </span>
            <span className="price">
              {moment(createDate).format("DD-MM-YY")}
            </span>
          </p>
          <p className="mid cardPara">
            <span>Status: </span>
            <span className={`price t-t-c ${projectStatus}`}>
              {projectStatus?.replace(formRegEx, formRegExReplacer)}
            </span>
          </p>

          {cus_chat && (
            <button
              className="btn-detail m-0"
              onClick={(e) => {
                if (!e) {
                  e = window.event;
                }
                e.stopPropagation(
                  history.push({
                    pathname: "/ChatScreen",
                    state: {
                      userId: user?._id,
                      projectId: cus_chat,
                    },
                  })
                );
              }}
            >
              Chat With Admin
            </button>
          )}
        </div>
        // )
      }
    </>
  );
}

export default ProjectCards;
