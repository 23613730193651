import React from "react";

const BodyLoader = ({ dark }) => {
  return (
    <div
      className="body-loader"
      // style={
      //   dark && {
      //     backgroundColor: "rgba(28,183,53, 0.95)",
      //   }
      // }
    >
      <div class="spinner-border green" role="status">
        {/* <span class="sr-only">Loading...</span> */}
      </div>
    </div>
  );
};

export default BodyLoader;
