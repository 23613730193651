import React, { useState, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import SideNavBar from "../components/SideNavBar";
import Header from "../components/header.jsx";
import ImageSelector from "../components/ImageSelector";
import AddProjectSignUpModal from "../components/AddProjectSignUpModal";
import Select from "react-select";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import ReactQuill from "react-quill";
import moment from "moment";
import MultiFileUpload, {
  imageTypes,
} from "../components/MultiFileUpload/MultiFileUpload";

function AddProject() {
  const history = useHistory();
  const dateref = useRef();
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);

  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [globalFormData, setGlobalFormData] = useState({});
  const [isSignupApiCall, setIsSignupApiCall] = useState(false);
  const [currency, setCurrency] = useState();
  const [requirement, setRequirement] = useState();

  // Image Selector states and fn
  const [galleryImages, setGalleryImages] = useState([{}, {}]);
  const handleGallerySubmit = (data) => {
    setGalleryImages(data);
  };

  // Pdf Selectors states and fn
  const [pdfs, setPdfs] = useState([{}, {}]);
  const handlePdfsSubmit = (data) => {
    setPdfs(data);
  };

  // theFiles
  const [theFiles, setTheFiles] = useState([]);
  const handleTheFilesChange = (files) => setTheFiles(files);

  //   Submit Project
  async function onSubmit(e) {
    e.preventDefault();

    if (
      title === "" ||
      amount === "" ||
      description === "" ||
      timePeriod === "" ||
      currency?.value === "" ||
      currency?.value == null
    ) {
      toast.error("Please fill out all the fields", {
        position: "top-center",
      });
      return;
    }
    if (amount < 0) {
      return toast.warn("Please enter a valid amount.");
    }
    if (!requirement || requirement.length < 0) {
      return toast.warn("requirement is required.");
    }

    const url = BaseURL("customer/post-project");

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("currency", currency?.value);
    formData.append("methodOfMeasurement", JSON.stringify(requirement));
    formData.append("timeline", moment(timePeriod).format("DD-MM-YYYY"));

    // for (let key in galleryImages) {
    //   // handle empty initial object

    // }
    // for (let key in pdfs) {
    //   // handle empty initial object
    //   if (pdfs[key]?.name) formData.append("pdfs", pdfs[key]);
    // }
    for (let key in theFiles) {
      if (theFiles[key]?.name) {
        if (imageTypes.includes(theFiles[key]?.type))
          formData.append("projectImages", theFiles[key]);
        else {
          formData.append("pdfs", theFiles[key]);
        }
      }
    }

    if (!accessToken) {
      setGlobalFormData(formData);
      setIsSignupModalOpen(true);
      return;
    }

    setIsApiCall(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Project added successfully", { position: "top-right" });
      setTimePeriod("");
      setTitle("");
      setAmount("");
      setCurrency("");
      setDescription("");
      setRequirement("");
      history.push({
        pathname: "/detailPage",
        state: response?.data?.data?._id,
      });
    } else {
      formData.delete("title");
      formData.delete("description");
      formData.delete("amount");
      formData.delete("timeline");
      for (let key in galleryImages) {
        formData.delete("projectImages");
      }
      for (let key in pdfs) {
        formData.delete("pdfs");
      }
    }
  }

  // projectPostAndSignupHandler
  const projectPostAndSignupHandler = async (params) => {
    for (let key in params) globalFormData.append(key, params[key]);

    const url = BaseURL("users/signup-with-project");
    setIsSignupApiCall(true);
    const response = await Post(url, globalFormData, apiHeader(false, true));
    setIsSignupApiCall(false);

    if (response !== undefined) {
      setIsSignupModalOpen(false);
      toast.success(
        "Please check your email. We have sent you a verification link."
      );
      history.replace("/login");
    }
  };

  return (
    <>
      <Header lg />
      <section className="add-project-section">
        <Container className="custom-container py">
          <Row>
            {accessToken && (
              <>
                <Col lg={3} md={false} className="display-sm-none">
                  <SideNavBar />
                </Col>
                <Col sm={12} className="display-md-none">
                  <ResponsiveMenu />
                </Col>
              </>
            )}
            <Col
              lg={accessToken ? 9 : 12}
              md={12}
              className="my-project-container"
            >
              <h2 className="py-4 addProjectHeading">Add Project</h2>
              <div className="addproject_form">
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col lg={6} md={12} className="field-margin">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="Project Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Col>
                    <Col lg={6} md={12} className="field-margin">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="Budget"
                        value={amount}
                        min={0}
                        // onChange={(e) => setAmount(e.target.value)}
                        onChange={(e) =>
                          setAmount(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                    <Col lg={6} md={12} className="field-margin">
                      <Select
                        className="Currencyinput"
                        options={options}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.value}
                        placeholder="Select Currency"
                        value={currency}
                        onChange={(e) => setCurrency(e)}
                      />
                    </Col>

                    <Col lg={6} md={12} className="field-margin">
                      <Form.Control
                        className="input"
                        type="text"
                        placeholder="Desired Completion Date"
                        value={timePeriod}
                        ref={dateref}
                        onChange={(e) => setTimePeriod(e.target.value)}
                        onFocus={() => (dateref.current.type = "date")}
                        onBlur={() => (dateref.current.type = "text")}
                      />
                    </Col>
                    <Col lg={6} md={12} className="field-margin">
                      <Select
                        isMulti
                        className="Currencyinput"
                        options={options2}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.value}
                        placeholder="Method of Measurement required"
                        value={requirement}
                        onChange={(e) => setRequirement(e)}
                      />
                    </Col>
                    <Col lg={12} md={12}>
                      {/* <Form.Control
                        as="textarea"
                        placeholder="Description"
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      /> */}
                      <label className="my-2">Description:</label>
                      <ReactQuill
                        className="input"
                        value={description}
                        onChange={(e) => setDescription(e)}
                      />
                    </Col>
                    {/* Images */}
                    {/* <Col lg={12} md={12}>
                      <div className="image-multi-container">
                        {galleryImages.map((item, index) => (
                          <ImageSelector
                            ley={index}
                            index={index}
                            item={item}
                            onSubmit={handleGallerySubmit}
                            initialState={galleryImages}
                          />
                        ))}
                        <ImageSelector
                          onSubmit={handleGallerySubmit}
                          initialState={galleryImages}
                          isAddEmpty={true}
                        />
                      </div>
                    </Col> */}
                    {/* Pdfs */}
                    {/* <Col lg={12} md={12}>
                      <div className="image-multi-container">
                        {pdfs.map((item, index) => (
                          <ImageSelector
                            key={index}
                            index={index}
                            item={item}
                            onSubmit={handlePdfsSubmit}
                            initialState={pdfs}
                            fileTypeArray={[
                              "application/pdf",
                              "application/msword",
                            ]}
                            fileType="pdf"
                          />
                        ))}
                        <ImageSelector
                          onSubmit={handlePdfsSubmit}
                          initialState={pdfs}
                          isAddEmpty={true}
                        />
                      </div>
                    </Col> */}

                    {/* Multi :) */}
                    <div className="mt-4" />
                    <MultiFileUpload onchange={handleTheFilesChange} />
                    <Col lg={12} md={12}>
                      <div className="btn-box">
                        <button className="" type="submit" disabled={isApiCall}>
                          {isApiCall ? "Submitting..." : "Add Project"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
      <AddProjectSignUpModal
        handleSubmit={projectPostAndSignupHandler}
        isLoading={isSignupApiCall}
        showModal={isSignupModalOpen}
        // showModal={true}
        closeModal={() => setIsSignupModalOpen(false)}
      />
    </>
  );
}

export default AddProject;

const options = [
  { value: "eur", label: "eur" },
  { value: "gbp", label: "gbp" },
];

const options2 = [
  {
    value: "ARM 4 – Agreed Rules of Measurement 4",
    label: "ARM 4 – Agreed Rules of Measurement 4",
  },
  {
    value: "NRM 2- New Rules of Measurement 2",
    label: "NRM 2- New Rules of Measurement 2",
  },
  {
    value: "CESMM3 – Civil Engineering Standard Method of Measurement 3",
    label: "CESMM3 – Civil Engineering Standard Method of Measurement 3",
  },
  {
    value: "CESMM4 – Civil Engineering Standard Method of Measurement 4",
    label: "CESMM4 – Civil Engineering Standard Method of Measurement 4",
  },
  {
    value: "SMM7 – Standard Method of Measurement 7",
    label: "SMM7 – Standard Method of Measurement 7",
  },
  {
    value: "RMM – Rail Method of Measurement",
    label: "RMM – Rail Method of Measurement",
  },
  {
    value: "POMI – Principles of Measurement (International)",
    label: "POMI – Principles of Measurement (International)",
  },
  {
    value:
      "My Required Method of Measurement is not listed – Please describe your needs in the Description area",
    label:
      "My Required Method of Measurement is not listed – Please describe your needs in the Description area",
  },
  {
    value: "Bespoke Method of Measurement – Please upload template",
    label: "Bespoke Method of Measurement – Please upload template",
  },
];
