import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import "./assets/styles/style.css";
import "./assets/styles/addProject.css";
import "./assets/styles/chatMessage.css";
import "./assets/styles/areYouSureModal.css";
import "./assets/styles/detail-freelancer.css";
import "./assets/styles/paymemt.css";
import "./assets/styles/chatScreen.css";
import "./assets/styles/Notification.css";
import "./assets/styles/profileImg.css";
import "./assets/styles/responsiveMenu.css";
import "./assets/styles/support.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Header from "./components/header.jsx";
import Homepage from "./pages/Homepage";
import Servicespage from "./pages/Servicespage";
import Order from "./pages/Order";
import Aboutus from "./pages/Aboutus";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignupPage";
import CustomerDashboard from "./pages/CustomerDashboard";
import Admindashboard from "./pages/Admindashboard.jsx";
import FreelancerDashboard from "./pages/FreelancerDashboard";
import Contactuspage from "./pages/Contactuspage";
import Footer from "./components/Footer";
import AddProject from "./pages/AddProject";
import ChatMessage from "./pages/ChatMessage";
import { ToastContainer, Zoom } from "react-toastify";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.min.css";
import InvoicesPage from "./pages/InvoicesPage";
import PaymentPage from "./pages/PaymentsPage";
import MyProject from "./pages/MyProject";
import { useDispatch, useSelector } from "react-redux";
import AssignedProject from "./pages/AssignedProject";
import DetailPage from "./pages/Detailpage";
import Payment from "./components/PaymentMethodList";
import FreeLancerDetail from "./components/FreelancerDetail";
import Notification from "./pages/Notification";
import { useEffect } from "react";
import ChatScreen from "./pages/chatScreen";
import ResponsiveMenu from "./components/ResponsiveMenu";
import Support from "./pages/Support";
import ProtectedRoute from "./pages/ProtectedRouter";

import CookieConsent from "react-cookie-consent";
import PrivacyPolicy2 from "./pages/PrivacyPolicy2";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import "./assets/styles/responsive.css";
import ChatNotifications from "./pages/ChatNotifications";
import Blog from "./pages/Blog/index";
import BlogDetail from "./pages/BlogDetail";

function App() {
  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const role = user?.role;
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonText="Ok"
        declineButtonText="No"
      >
        we use cookies to ensure that we give you the best experience on our
        website, if you continue to use this site we will assume that you are
        happy with it.
        <span
          className="privacy-link c-p ms-2"
          style={{ color: "#07bc0c" }}
          onClick={() => (window.location.href = "/privacyPolicy")}
        >
          Privacy Policy
        </span>
      </CookieConsent>

      <Router>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:id" component={BlogDetail} />
          <Route exact path="/services" component={Servicespage} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/privacyPolicy2" component={PrivacyPolicy2} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/contactus" component={Contactuspage} />
          <Route exact path="/login" component={SignInPage} />
          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/add-project" component={AddProject} />
          {/* resp done */}
         
          <ProtectedRoute exact path="/PaymentMethod" component={Payment} />
          <ProtectedRoute exact path="/detailPage" component={DetailPage} />
          <ProtectedRoute exact path="/myproject" component={MyProject} />
          <ProtectedRoute exact path="/payment" component={PaymentPage} />
          <ProtectedRoute exact path="/Invoice" component={InvoicesPage} />
          <ProtectedRoute
            exact
            path="/dashboard"
            component={
              role == "customer" ? CustomerDashboard : FreelancerDashboard
            }
          />
          <ProtectedRoute exact path="/ChatScreen" component={ChatScreen} />
          <ProtectedRoute
            exact
            path="/AssignedProject"
            component={AssignedProject}
          />
          <ProtectedRoute
            exact
            path="/project-detail"
            component={FreeLancerDetail}
          />
          <ProtectedRoute exact path="/notification" component={Notification} />
          <ProtectedRoute
            exact
            path="/chat-notifications"
            component={ChatNotifications}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
