import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import masterLogo from "../assets/images/Mastercard-logo.png";
import delete_icon from "../assets/images/delete_icon.png";
import visa from "../assets/images/visa.png";
import jcb from "../assets/images/jcb.png";
import AddNewCard from "./AddNewCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BaseURL, stripe_public_key } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import AreYouSureModal from "./AreYouSureModal";
import NullDataComponent from "./NullDataComponent";
const PaymentMethodsList = (props) => {
  // const [selectedId , setSelectedId] = useState();
  const {
    HandleShowAddNewCard,
    PaymentMethodList,
    HandleDetachCard,
    loading,
    setSelectedId,
    selectedId,
  } = props;
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [detachCardId, setDetachCardId] = useState(null);
  function decideCard(card) {
    let cardImg =
      (card === "mastercard" && masterLogo) || (card === "visa" && visa);

    if (card == "visa") {
      cardImg = visa;
    } else if (card == "mastercard") {
      cardImg = masterLogo;
    } else if (card == "jcb") {
      cardImg = jcb;
    }
    return cardImg;
  }

  return (
    <Row className="paymentCards gx-0">
      <Col lg={12}>
        <div className="payment">
          <h5 className="small">Payment Method</h5>
          <div className="payment_col">
            <button
              className="btn-detail"
              onClick={() => HandleShowAddNewCard()}
            >
              Add New Card
            </button>
          </div>
        </div>
      </Col>
      <Col lg={12}>
        {PaymentMethodList?.length > 0 ? (
          <>
            <div className="balanceCard">
              {PaymentMethodList.map((item, index) => (
                <>
                  <div
                    className={
                      item?.id == selectedId
                        ? "custm_masterCard custm_masterCard_Active"
                        : "custm_masterCard"
                    }
                    style={{
                      border: item?.id == selectedId && "2px solid #1cb735",
                    }}
                    onClick={() => setSelectedId(item?.id)}
                  >
                    <Row>
                      <Col lg={4} className="custmImg">
                        <div>
                          <img src={decideCard(item?.card?.brand)} />
                        </div>
                        <div>
                          <p className="small">{item?.card?.brand}</p>
                          <p className="small numPara">{`********${item?.card?.last4}`}</p>
                        </div>
                      </Col>
                      <Col lg={4} className="custmImg1">
                        <p className="small">Expiration date</p>
                        <p className="small numPara">{`*******${item?.card?.exp_month}/${item?.card?.exp_year}`}</p>
                      </Col>
                      <Col lg={4} className="custmImg2">
                        <div
                          className="deleteIcon c-p"
                          onClick={() => {
                            setDetachCardId(item?.id);
                            setOpen(true);
                            // HandleDetachCard(item?.data?.id);
                          }}
                        >
                          <img src={delete_icon} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <div className="card_div">
            <NullDataComponent sm text={`No Card Added Yet...`} />
          </div>
        )}
      </Col>
      <AreYouSureModal
        open={open}
        setOpen={setOpen}
        closeModal={closeModal}
        onClick={() => HandleDetachCard(detachCardId, closeModal)}
        isLoading={loading}
        title={"Are You Sure You Want To Delete This Card"}
        modalbox2={true}
      />
    </Row>
  );
};

export default PaymentMethodsList;
