import React ,{useState} from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import LatestNewImg1 from "../assets/images/Mask Group 8.png";
import LatestNewImg2 from "../assets/images/Mask Group 7.png";
import LatestNewImg3 from "../assets/images/Mask Group 6.png";
import { BsFillClockFill } from "react-icons/bs";
import "../assets/styles/LatestNews.css";
import { imageUrl } from "../Config/apiUrl";
import moment from "moment";
import ViewMoreModal from "./ViewMoreModal";
import { useHistory } from "react-router-dom";
function LatestNew({ data }) {
  const history = useHistory();
  const [modalData, setModalData] = useState({});

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const openCheck = (imageUrl, title, description) => {
    setOpenDetailModal(true);
    setModalData({ imageUrl, title, description });
  };
  return (
    <>
      <section>
        <Container className="latestNew-container mb-5">
          <Row>
          <Col lg={12}>
              <div className="NewsCard-section" data-aos="fade-up">
                <h1>{data?.sec7Heading}</h1>
              </div>
            </Col>
          </Row>
          <Row className="latestNew-row">
            {data["our-latest-news"]?.map((item) => {
              return (
                <Col lg={4} md={6} sm={6} xs={12} className="latestNews-768" >
                  <div className="NewsCard">
                    <Card>
                      <div className="latestNews-img-box">
                      <Card.Img
                        className="latestNews-img"
                        variant="top"
                        src={`${imageUrl}${item?.image}`}
                      />
                      </div>
                      <Card.Body>
                        <Card.Text>
                          <span>
                            <BsFillClockFill className="clock-icon" />
                          </span>
                          <span className="time">
                            {moment(item?.createdAt).format("MMMM d, Y")}{" "}
                          </span>
                        </Card.Text>
                        <Card.Title className="latestNewsTitle">
                          <h4>{item?.title}</h4>
                        </Card.Title>
                        <Card.Text>
                          <p className="small">{item?.description}</p>
                          <p className="mid c-p"
                          onClick={()=>openCheck(item?.image,item?.title,item?.description)}
                          >
                            <span>Read more...</span>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            })}
            {/* <Col lg={12}>
              <div className="NewCardsbtn" data-aos="zoom-out-up">
                <button
                >View More</button>
              </div>
            </Col> */}
          </Row>
        </Container>
        <ViewMoreModal
          open={openDetailModal}
          setOpen={setOpenDetailModal}
          modalData={modalData}
        />
      </section>
    </>
  );
}

export default LatestNew;
