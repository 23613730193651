import React from "react";
import { Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useHistory } from "react-router-dom";

import { numberWithCommas, pdfUrl } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { currencies } from "../pages/currencies";
import { FaFilePdf } from "react-icons/fa";
import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function Payment({ payments, search = "loc=3" }) {
  const history = useHistory();
  const user = useSelector((state) => state?.authReducer?.user);

  return (
    <>
      <Col lg={12}>
        <div className="invoice-Table">
          <table>
            <thead className="invoice-head">
              <tr>
                <th>No.</th>
                <th>Project Title</th>
                <th>Milestone Title</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Invoice</th>
                <th>Invoice Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {payments?.map((item, index) => (
                <tr className="invoice-row">
                  <td>{index + 1}</td>
                  <td>{item?.projectId?.title}</td>
                  <td className="t-t-c">{item.paymentMilestone?.title}</td>
                  <td>
                    {/* {item.paymentMilestone?.amount} */}
                    {item?.currency &&
                      currencies[item?.currency?.toUpperCase()].symbol}
                    {/* {numeral(item.paymentMilestone?.amount).format("0.0a")} */}
                    {numberWithCommas(item.paymentMilestone?.amount)}
                  </td>
                  <td className={`t-t-c ${item.paymentMilestone?.status}`}>
                    {item.paymentMilestone?.status}
                  </td>
                  <td
                    className="c-p"
                    onClick={() =>
                      window.open(`${pdfUrl}${item?.paymentMilestone?.invoice}`)
                    }
                  >
                    <span>Open PDF</span>
                    <FaFilePdf className="close-icon" />
                    <span></span>
                  </td>
                  <td>
                    {item?.paymentMilestone?.pdfPassword ? (
                      <CopyToClipboard
                        text={item?.paymentMilestone?.pdfPassword}
                        onCopy={() =>
                          toast.success("Password copied to clipboard.")
                        }
                      >
                        <div className="c-p">
                          <AiOutlinePaperClip style={{ fontSize: "20px" }} />
                          <span>(Click to Copy)</span>
                        </div>
                      </CopyToClipboard>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <button
                      className="btn-detail m-0"
                      onClick={() =>
                        history.push({
                          pathname:
                            user?.role == "customer"
                              ? "/detailPage"
                              : "/project-detail",
                          state: item?.projectId?._id,
                          search,
                        })
                      }
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </>
  );
}
