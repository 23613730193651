import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import "../assets//styles/Dashboardpage.css";
import "../assets/styles/customerdashboard.css";
import invoiceimg from "../assets/images/invoice.png";
import { BsCheck } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { imageUrl, pdfUrl } from "../Config/apiUrl";
import { FaFilePdf } from "react-icons/fa";
import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function Invoice({ payments, search = "loc=2" }) {
  const history = useHistory();

  return (
    <>
      <section className="Dashboardpage-section">
        <Col lg={12}>
          <div className="invoice-Table">
            <table>
              <thead className="invoice-head">
                <tr>
                  <th>No.</th>
                  <th>Project Title</th>
                  <th>Milestone Title</th>
                  <th>Status</th>
                  <th>Invoice</th>
                  <th>Invoice Password</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {payments?.map((item, index) => {
                  return (
                    <tr className="invoice-row">
                      <td>{index + 1}</td>
                      <td className="t-t-c">
                        {item?.projectDetail?.projectTitle}
                      </td>
                      <td className="t-t-c">{item?.milestoneDetails?.title}</td>
                      <td className={`t-t-c ${item?.milestoneDetails?.status}`}>
                        {item?.milestoneDetails?.status}
                      </td>
                      <td
                        className="c-p"
                        onClick={() =>
                          window.open(
                            `${pdfUrl}${item?.milestoneDetails?.invoice}`
                          )
                        }
                      >
                        <span>Open PDF</span>
                        <FaFilePdf className="close-icon" />
                        <span></span>
                      </td>
                      <td>
                        {item?.milestoneDetails?.pdfPassword ? (
                          <CopyToClipboard
                            text={item?.milestoneDetails?.pdfPassword}
                            onCopy={() =>
                              toast.success("Password copied to clipboard.")
                            }
                          >
                            <div className="c-p">
                              <AiOutlinePaperClip
                                style={{ fontSize: "20px" }}
                              />
                              <span>(Click to Copy)</span>
                            </div>
                          </CopyToClipboard>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <button
                          className="btn-detail m-0"
                          onClick={() =>
                            history.push({
                              pathname: "/detailPage",
                              state: item?.projectDetail?.projectId,
                              search,
                            })
                          }
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </section>
    </>
  );
}
