import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import "../assets/styles/Admindashboard.css";

export default function TermsAndConditionsModal({
  showTermModal,
  closeTermModal,
  termsText,
  payMileStone,
  isAcceptApiCall,
}) {
  const [accept, setAccept] = useState(false);
  return (
    <Modal
      show={showTermModal}
      onHide={closeTermModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Terms And Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactQuill readOnly={true} value={termsText} />

        <p>
          <input type="checkbox" onChange={() => setAccept((prev) => !prev)} />
          <label className="ms-2" for="accept">
            {" "}
            I agree to all terms and conditions
          </label>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            if (!accept)
              return toast.warn("You must agree to the terms and conditions.");
            payMileStone(null, true);
            closeTermModal();
          }}
          disabled={
            (accept === true ? false : true) ||
            (isAcceptApiCall === true && true)
          }
          className="modal-btn"
        >
          {isAcceptApiCall ? "Please Wait..." : "Accept"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
