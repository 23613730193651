import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useSelector } from "react-redux";
import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
  getSpecificSearchParam,
  imageUrl,
  numberWithCommas,
  pdfUrl,
  stripe_public_key,
} from "../Config/apiUrl";
import { Delete, Get, Post } from "../Axios/AxiosFunctions";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SideNavBar from "../components/SideNavBar";
import Header from "../components/header";
import { BiRightArrow } from "react-icons/bi";
import AcceptProposalModal from "../components/AcceptProposalModal";
import { FaFilePdf } from "react-icons/fa";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";
import { currencies } from "../pages/currencies";
import { useHistory } from "react-router-dom";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import TermsAndConditionsModal from "../components/TermsAndConditionModal";
import TableRowsLoader from "../components/TableRowsLoader";

import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ProposalRejectReasonModal from "../components/ProposalRejectReasonModal";

export default function DetailPage() {
  const loc = getSpecificSearchParam("loc");

  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [
    milestonestoneIdThatWeArePayingFor,
    setMilestonestoneIdThatWeArePayingFor,
  ] = useState(null);

  const stripePromise = useMemo(
    () => loadStripe(stripe_public_key),
    [stripe_public_key]
  );

  const [showTermModal, setShowTermModal] = useState(false);
  const closeTermModal = () => setShowTermModal(false);

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  // const project = useLocation()?.state;
  const projectId = useLocation()?.state;

  // changed states
  const [project, setProject] = useState({});
  const [_proposalForCustomer, set_ProposalForCustomer] = useState();
  const [milestones, setMilestones] = useState([]);
  const [amount, setAmount] = useState(0);

  const [isRejectApiCall, setIsRejectApiCall] = useState(false);
  const [isAcceptApiCall, setIsAcceptApiCall] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isMarkingAsComplete, setIsMarkingAsComplete] = useState(false);
  const [delApi, setDelApi] = useState(false);

  const [isRejectionReasonModalOpen, setIsRejectionReasonModalOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // RejectProposal
  async function RejectProposal(rejectionReason) {
    const url = BaseURL(
      `customer/customer-action-on-porposal?status=rejected&proposalId=${_proposalForCustomer?._id}`
    );

    setIsRejectApiCall(true);
    const response = await Post(
      url,
      {
        rejectionReason,
      },
      apiHeader(accessToken)
    );
    setIsRejectApiCall(false);
    if (response !== undefined) {
      set_ProposalForCustomer(response?.data.data);
      toast.success("Proposal rejected successfully.");
      setIsRejectionReasonModalOpen(false);
    }
  }

  //  MileStone Pay Api
  const payMileStone = async (selectedId, initial = false) => {
    let url;

    // for initial milestone
    if (initial) {
      url = BaseURL(
        `customer/customer-action-on-porposal?status=accepted&proposalId=${_proposalForCustomer?._id}`
      );
    } else {
      url = BaseURL(
        `customer/customer-Pay-to-admin?proposalId=${_proposalForCustomer?._id}&pmId=${selectedId}&milestoneId=${milestonestoneIdThatWeArePayingFor}`
      );
    }

    setIsLoading(true);
    setIsAcceptApiCall(true);
    let requestResponse = await Post(url, {}, apiHeader(accessToken));
    if (requestResponse !== undefined) {
      await getProjectData(false);
      toast.success(
        initial
          ? "You have successfully accepted admin's proposal."
          : "You have successfully paid for milestone."
      );
      setShowModal(false);
    }
    setIsLoading(false);
    setIsAcceptApiCall(false);
  };

  // const getProjectData
  async function getProjectData(loader = true) {
    const url = BaseURL(`users/projectDetails?projectId=${projectId}`);
    loader && setIsApiCall(true);
    const response = await Get(url, accessToken);
    loader && setIsApiCall(false);

    if (response !== undefined) {
      let _r = response?.data.data;
      let _proposalforCus;
      // if accepted ? showUpdated : else show previous
      if (_r?.accecptedPorposalByCustomer) {
        set_ProposalForCustomer(_r?.accecptedPorposalByCustomer);
        _proposalforCus = _r?.accecptedPorposalByCustomer;
      } else {
        _proposalforCus = _r?.porposalsForCustomer?.find(
          (e) => e?.status == "active"
        );

        if (!_proposalforCus) {
          _proposalforCus = _r?.porposalsForCustomer?.find(
            (e) => e?.status == "rejected"
          );
        }
        set_ProposalForCustomer(_proposalforCus);
      }

      set_ProposalForCustomer(_proposalforCus);
      setMilestones(_proposalforCus?.milestones);
      setAmount(_proposalforCus?.milestones[0]?.amount);
      setProject(response?.data?.data);
    }
  }

  // handleMarkAsComplete
  const handleMarkAsComplete = async () => {
    if (project?.projectStatus === "completed") return;
    const url = BaseURL(
      `customer/update-project-status?projectId=${projectId}&status=completed`
    );

    setIsMarkingAsComplete(true);
    const response = await Post(url, {}, apiHeader(accessToken));
    setIsMarkingAsComplete(false);

    if (response !== undefined) {
      await getProjectData(false);
      toast.success("Project marked as completed successfully.");
    }
  };

  // delete project
  const deleteProject = async () => {
    const url = BaseURL(`customer/delete-project/${projectId}`);
    setDelApi(true);
    const response = await Delete(url, apiHeader(accessToken));
    setDelApi(false);

    if (response !== undefined) {
      toast.success("Project deleted successfully.");
      history.push("/add-project");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getProjectData();
  }, []);

  return (
    <>
      <Header lg />
      {/* <section className="Dashboardpage-section"> */}
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col lg={9}>
            {isApiCall ? (
              <TableRowsLoader />
            ) : (
              <>
                <Row>
                  <div className="breedCum mt-4">
                    <span>
                      {loc == 1
                        ? "My Projects"
                        : loc == 2
                        ? "Invoices"
                        : loc == 3
                        ? "Payments"
                        : loc == 4
                        ? "Notifications"
                        : "My Project"}
                    </span>
                    <BiRightArrow className="ic" />
                    <span>Project Detail</span>
                  </div>
                  <div className="detail_card_box m-p">
                    <Col lg={12}>
                      <div className="detail-box nopd">
                        <p>
                          <span className="tb">Title : </span>
                          <span className="amt t-t-c tb">
                            {`${project?.title}`}
                          </span>
                        </p>
                        <div className="detail-inner-box">
                          <p>
                            {" "}
                            <span className="tb">Amount : </span>
                            <span className="amt">
                              {project?.currency &&
                                currencies[project?.currency?.toUpperCase()]
                                  .symbol}
                              {/* {numeral(project?.amount).format("0.0a")}{" "} */}
                              {numberWithCommas(project?.amount)}
                            </span>
                          </p>

                          <p>
                            {" "}
                            <span className={`tb`}>Project Status: </span>
                            <span
                              className={`amt t-t-c ${project?.projectStatus}`}
                            >{`${project?.projectStatus?.replace(
                              formRegEx,
                              formRegExReplacer
                            )}`}</span>
                          </p>
                        </div>
                        <p className="tb">Description :</p>
                        <p className="box-descript">
                          <ShowMoreShowLessText
                            quill={true}
                            text={project?.description}
                            visibility={345}
                          />
                        </p>
                        <p className="tb">Method Of Measurement :</p>
                        <p className="box-descript">
                          {project?.methodOfMeasurement?.map((item, index) => (
                            <li>{item?.value}</li>
                          ))}
                        </p>
                      </div>

                      {["underReview", "pending"].includes(
                        project?.projectStatus
                      ) && (
                        <button
                          className="btn-detail"
                          onClick={() => {
                            deleteProject();
                          }}
                        >
                          {delApi ? "please wait..." : "Delete"}
                        </button>
                      )}
                      {project?.porposalsForCustomer?.length === 0 && (
                        <button
                          className="btn-detail"
                          onClick={() =>
                            history.push({
                              pathname: "/ChatScreen",
                              state: {
                                userId: user?._id,
                                projectId: projectId,
                              },
                            })
                          }
                        >
                          Chat With Admin
                        </button>
                      )}
                    </Col>

                    {/* Project Images */}
                    {project?.images?.length > 0 && (
                      <Row className="selectImg_box">
                        <Col md={12}>
                          <h5>Images</h5>
                        </Col>
                        {project?.images.map((image, index) => (
                          <Col lg={4} key={index}>
                            <div
                              className="selectimgs c-p"
                              onClick={() =>
                                window.open(`${imageUrl}${image}`, "_blank")
                              }
                            >
                              <img
                                src={`${imageUrl}${image}`}
                                alt="project-img"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}

                    {/* Project Docs */}
                    {project?.pdfs?.length > 0 && (
                      <Row className="selectImg_box">
                        <Col md={12}>
                          <h5>Drawings and Specifications</h5>
                        </Col>
                        {project?.pdfs?.map((document, index) => (
                          <Col lg={4} key={index}>
                            <div
                              className="selectimgs select_pdf c-p"
                              onClick={() =>
                                window.open(`${pdfUrl}${document}`, "_blank")
                              }
                            >
                              <FaFilePdf className="pdf_icon" />
                              <p>Open PDF</p>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}

                    {_proposalForCustomer &&
                      milestones.every((mile) => mile?.isMilestonePaid) &&
                      ["completed", "inProgress"].includes(
                        project?.projectStatus
                      ) && (
                        <div className="detailbtn-container">
                          <button
                            className={`btn-detail m-0 ${
                              (isMarkingAsComplete ||
                                project?.projectStatus === "completed") &&
                              "disableBtn-xxx"
                            }`}
                            onClick={handleMarkAsComplete}
                            disabled={
                              isMarkingAsComplete ||
                              project?.projectStatus === "completed"
                            }
                          >
                            {project?.projectStatus == "inProgress"
                              ? "Mark This Project as Complete"
                              : "The Project was marked as Completed"}
                          </button>
                          {project?.projectStatus !== "inProgress" && (
                            <>
                              <button
                                className="btn-detail"
                                onClick={() =>
                                  window.open(
                                    `${pdfUrl}${_proposalForCustomer?.invoice}`,
                                    "_blank"
                                  )
                                }
                              >
                                View Receipt
                              </button>
                              <CopyToClipboard
                                text={_proposalForCustomer?.pdfPassword}
                                onCopy={() =>
                                  toast.success("Password copied to clipboard.")
                                }
                              >
                                <div className="c-p">
                                  <AiOutlinePaperClip
                                    style={{ fontSize: "20px" }}
                                  />
                                  <span>(Click to Copy)</span>
                                </div>
                              </CopyToClipboard>
                            </>
                          )}
                        </div>
                      )}
                  </div>

                  {_proposalForCustomer && (
                    <>
                      <Col lg={12} className="g-0">
                        <div className="detail_card_box detail-box mt-5">
                          <h3>Admin's Proposal</h3>
                          <button
                            className="btn-detail"
                            onClick={() =>
                              history.push({
                                pathname: "/ChatScreen",
                                state: {
                                  userId: _proposalForCustomer?.sendTo?._id,
                                  projectId: _proposalForCustomer?.projectId,
                                  proposalId: _proposalForCustomer?._id,
                                },
                              })
                            }
                          >
                            Chat With Admin
                          </button>

                          <div className="detail-inner-box">
                            <p>
                              <span className="tb">Title : </span>
                              <span className="amt t-t-c">
                                {_proposalForCustomer?.title}
                              </span>
                            </p>
                            <p>
                              <span className="tb">Amount : </span>
                              <span className="amt">
                                {project?.currency &&
                                  currencies[project?.currency?.toUpperCase()]
                                    .symbol}
                                {/* {numeral(_proposalForCustomer?.amount).format(
                                  "0,00a"
                                )} */}
                                {numberWithCommas(_proposalForCustomer?.amount)}
                              </span>
                            </p>
                          </div>

                          {/* status */}
                          <p className="mb-3">
                            <span className="tb">Proposal Status: </span>
                            <span className={`amt t-t-c`}>
                              <span
                                style={{
                                  fontFamily: "Poppins Regular",
                                }}
                                className={`${_proposalForCustomer?.status}`}
                              >
                                {_proposalForCustomer?.status?.replace(
                                  formRegEx,
                                  formRegExReplacer
                                )}
                              </span>
                            </span>
                          </p>

                          {/* proposalDate */}
                          <p className="mb-3">
                            <span className="tb">Project End Date: </span>
                            <span className={`amt t-t-c`}>
                              <span
                                style={{
                                  fontFamily: "Poppins Regular",
                                }}
                              >
                                {_proposalForCustomer?.proposalDate}
                              </span>
                            </span>
                          </p>
                          <p className="tb">Description :</p>
                          <p className="box-descript">
                            <ShowMoreShowLessText
                              quill={true}
                              text={_proposalForCustomer?.description}
                              visibility={345}
                            />
                          </p>

                          <div className="invoice-Table">
                            <p className="tb">MileStone:</p>
                            <table>
                              <thead className="invoice-head">
                                <tr>
                                  <th>No.</th>
                                  <th>Project Title</th>
                                  <th>Description</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                  <th>Paid (By You)</th>
                                  <th>Release Request</th>
                                  <th>Receipt</th>
                                  <th>Receipt Password</th>
                                </tr>
                              </thead>
                              <tbody>
                                {milestones?.map((item, index) => {
                                  // amountValue = item?.amount ;
                                  return (
                                    <tr className="invoice-row" key={index}>
                                      <td>{index + 1}</td>
                                      <td className="t-t-c">{item?.title}</td>
                                      <td>
                                        <ShowMoreShowLessText
                                          text={item?.description}
                                          visibility={8}
                                        />
                                      </td>
                                      <td>
                                        {project?.currency &&
                                          currencies[
                                            project?.currency?.toUpperCase()
                                          ].symbol}
                                        {/* {numeral(item?.amount).format("0.0a")} */}
                                        {numberWithCommas(item?.amount)}
                                      </td>
                                      <td className={`t-t-c ${item?.status}`}>
                                        {item?.status?.replace(
                                          formRegEx,
                                          formRegExReplacer
                                        )}
                                      </td>
                                      <td>
                                        {item?.isMilestonePaid ? "Yes" : "No"}
                                      </td>
                                      <td>
                                        {item?.makeReleaseRequest &&
                                        item?.isMilestonePaid ? (
                                          "Released"
                                        ) : item?.makeReleaseRequest ? (
                                          <button
                                            className="btn-detail m-0"
                                            onClick={() => {
                                              setMilestonestoneIdThatWeArePayingFor(
                                                item?._id
                                              );
                                              setAmount(item?.amount);
                                              setShowModal(true);
                                            }}
                                          >
                                            Pay Now
                                          </button>
                                        ) : (
                                          "None"
                                        )}
                                      </td>
                                      <td>
                                        {item?.invoice ? (
                                          <div
                                            className="c-p"
                                            onClick={() =>
                                              window.open(
                                                `${pdfUrl}${item?.invoice}`,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <span>Open PDF</span>
                                            <FaFilePdf className="close-icon" />
                                            {/* <span>Click to Copy</span> */}
                                          </div>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {item?.pdfPassword ? (
                                          <CopyToClipboard
                                            text={item?.pdfPassword}
                                            onCopy={() =>
                                              toast.success(
                                                "Password copied to clipboard."
                                              )
                                            }
                                          >
                                            <div className="c-p">
                                              <AiOutlinePaperClip
                                                style={{ fontSize: "20px" }}
                                              />
                                              <span>(Click to Copy)</span>
                                            </div>
                                          </CopyToClipboard>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {["active"].includes(
                            _proposalForCustomer?.status
                          ) && (
                            <div className="btn-x">
                              <button
                                className="btn-detail"
                                onClick={() => {
                                  // setShowModal(true);
                                  // payMileStone(null, true);
                                  setShowTermModal(true);
                                }}
                                // disabled={isRejectApiCall || isAcceptApiCall}
                              >
                                {/* {isAcceptApiCall ? "Please Wait..." : "Accept"} */}
                                Accept
                              </button>
                              <button
                                className="btn-detail rx"
                                // onClick={() => RejectProposal()}
                                onClick={() =>
                                  setIsRejectionReasonModalOpen(true)
                                }
                                disabled={isRejectApiCall || isAcceptApiCall}
                              >
                                {isRejectApiCall ? "Please Wait..." : "Reject"}
                              </button>
                              {/* <button
                            className="btn-detail"
                            onClick={() =>
                              window.open(
                                `${pdfUrl}${project?.privacyPolicyForCustomer}`,
                                "_blank"
                              )
                            }
                          >
                             View Terms & Conditions
                          </button> */}
                            </div>
                          )}
                          {_proposalForCustomer?.status === "rejected" && (
                            <div className="btn-x">
                              <button className="btn-detail rx" disabled={true}>
                                You have rejected Admin's Proposal
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}
          </Col>
          <Elements stripe={stripePromise}>
            <AcceptProposalModal
              payMileStone={payMileStone}
              isLoading={isLoading}
              amount={amount}
              showModal={showModal}
              closeModal={closeModal}
              project={project}
            />
          </Elements>

          <TermsAndConditionsModal
            closeTermModal={closeTermModal}
            showTermModal={showTermModal}
            termsText={project?.privacyPolicyForCustomer}
            payMileStone={payMileStone}
            isAcceptApiCall={isAcceptApiCall}
          />

          <ProposalRejectReasonModal
            open={isRejectionReasonModalOpen}
            setOpen={setIsRejectionReasonModalOpen}
            isLoading={isRejectApiCall}
            onClick={RejectProposal}
          />
        </Row>
      </Container>
      <Footer />
      {/* </section> */}

      {/* {isApiCall && <BodyLoader />} */}
    </>
  );
}
