import React, { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useSelector } from "react-redux";
import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
  getSpecificSearchParam,
  imageUrl,
  numberWithCommas,
  pdfUrl,
} from "../Config/apiUrl";
import { Get, Patch, Post } from "../Axios/AxiosFunctions";
import { useLocation } from "react-router-dom";

import AreYouSureModal from "../components/AreYouSureModal";
import MaskGroup from "../assets/images/Mask Group new.png";
import { FaFilePdf } from "react-icons/fa";

import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";
import ReactHtmlParser from "react-html-parser";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";
import Header from "./header";
import SideNavBar from "./SideNavBar";
import BankingDetailModal from "./BankingDetailModal";
import { useHistory } from "react-router-dom";
import ResponsiveMenu from "./ResponsiveMenu";
import Footer from "./Footer";
import TableRowsLoader from "./TableRowsLoader";

import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiRightArrow } from "react-icons/bi";
import { currencies } from "../pages/currencies";
import FreelancerRejectionModal from "./FreelancerRejectionModal";

var numeral = require("numeral");

export default function FreeLancerDetail() {
  const loc = getSpecificSearchParam("loc");

  const history = useHistory();

  // console.log("loc ", getSpecificSearchParam('loc'))
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [isdaysleftSend, setIsDaysleftsend] = useState(false);
  const [isApiCall, setIsApicall] = useState(false);
  const [isRequestReleaseModalOpen, setIsRequestReleaseModalOpen] =
    useState(false);

  // mark as complete
  const [isMarkAsCompleteModalOpen, setIsMarkAsCompleteModalOpen] =
    useState(false);
  const [isMarkAsCompleteApiCall, setIsMarkAsCompleteApiCall] = useState(false);
  const [markAsCompleteData, setMarkAsCompleteData] = useState({});

  const projectId = useLocation()?.state;
  const [projectCompleteness, setProjectCompleteness] = useState({
    class: "",
    value: 0,
  });
  const [isRejectionDetailModalOpen, setIsRejectionDetailModalOpen] =
    useState(false);

  const [detail, setDetail] = useState([]);
  const [daysLeft, setDaysLeft] = useState("");
  const [adminforFreelancer, setAdminForFreelancer] = useState({});

  // Release Request
  const [isMakingReleaseRequest, setIsMakingReleaseRequest] = useState(false);
  const [releaseMilestoneId, setReleaseMilestoneId] = useState("");

  const [isAcceptingProposal, setIsAcceptingProposal] = useState(false);
  const [isRejectingProposal, setIsRejectingProposal] = useState(false);

  // get data
  async function getData(loader = true) {
    const url = BaseURL(`users/projectDetails?projectId=${projectId}`);
    loader && setIsApicall(true);
    const response = await Get(url, accessToken);
    loader && setIsApicall(false);
    if (response !== undefined) {
      let _r = response?.data?.data;
      setDetail(_r);
      setDaysLeft(_r?.noOfDays);

      // if accepted ? showUpdated : else show previous
      if (_r?.accecptedPorposalByFreelancer) {
        setAdminForFreelancer(_r?.accecptedPorposalByFreelancer);
      } else {
        let _proposal = _r?.porposalsForFreelancer?.find(
          (e) => e?.sendTo?._id == user?._id && e?.status == "active"
        );

        if (!_proposal) {
          _proposal = _r?.porposalsForFreelancer?.find(
            (e) => e?.sendTo?._id == user?._id && e?.status == "rejected"
          );
        }
        setAdminForFreelancer(_proposal);
      }

      // Project Completeness
      let progressClass = "danger";
      let progressValue = (_r.amountPayedToFreelancer / _r.amount) * 100;

      if (progressValue <= 10) progressClass = "danger";
      else if (progressValue > 10 && progressValue <= 30)
        progressClass = "warning";
      else if (progressValue > 30 && progressValue <= 80)
        progressClass = "primary";
      else if (progressValue > 80 && progressValue <= 100)
        progressClass = "success";
      else progressClass = "danger";

      setProjectCompleteness({
        class: progressClass,
        value: progressValue,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  // releaseRequestHandler
  const releaseRequestHandler = async (mileId, widthDrawlDetail) => {
    // const url = BaseURL(
    //   `freelancer/make-milestone-widthdrawlRequest?milestoneId=${releaseMilestoneId}&proposalId=${adminforFreelancer._id}&widthDrawlDetail=${widthDrawlDetail}`
    // );
    const url = BaseURL(
      `freelancer/make-milestone-widthdrawlRequest?milestoneId=${mileId}&proposalId=${adminforFreelancer._id}`
    );

    setIsMakingReleaseRequest(true);
    const response = await Post(url, {}, apiHeader(accessToken));

    if (response !== undefined) {
      await getData(false);
      toast.success("Withdrawal Request Sent successfully");
      setIsRequestReleaseModalOpen(false);
    }
    setIsMakingReleaseRequest(false);
  };

  //   handleProposalAcceptOrReject
  const handleProposalAcceptReejct = async (status = "accepted", reason) => {
    const url = BaseURL(
      `freelancer/freelancer-action-on-porposal?status=${status}&proposalId=${adminforFreelancer?._id}`
    );

    status == "accepted" && setIsAcceptingProposal(true);
    status !== "accepted" && setIsRejectingProposal(true);
    const response = await Post(
      url,
      { rejectionReason: reason },
      apiHeader(accessToken)
    );
    status == "accepted" && setIsAcceptingProposal(false);
    status !== "accepted" && setIsRejectingProposal(false);

    if (response !== undefined) {
      await getData(false);
      toast.success(`Proposal ${status} successfully`);
      setIsRequestReleaseModalOpen(false);
      setIsRejectionDetailModalOpen(false);
    }
  };

  const SendNoOfDays = async () => {
    const url = BaseURL(`freelancer/update-project-progress/${projectId}`);

    const params = {
      noOfDays: daysLeft,
    };
    setIsDaysleftsend(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsDaysleftsend(false);
    if (response !== undefined) {
      toast.success(`No of Days Left send successfully`);
    }
  };

  //   markMiletoneAsCompleteHandler
  const markMiletoneAsCompleteHandler = async () => {
    let milestonesCopy = [...adminforFreelancer?.milestones];
    const selectedMileIndex = milestonesCopy.findIndex(
      (e) => e?._id == markAsCompleteData?._id
    );

    if (selectedMileIndex !== -1) {
      for (let i = 0; i < selectedMileIndex; i++) {
        if (milestonesCopy[i]?.status == "pending") {
          return toast.error(
            "You can't mark this milestone as complete because previous milestone(s) are in pending states."
          );
        }
      }
    }

    const url = BaseURL(
      `freelancer/update-milestone-status?status=completed&milestoneId=${markAsCompleteData?._id}&proposalId=${adminforFreelancer?._id}`
    );
    setIsMarkAsCompleteApiCall(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      await getData(false);
      toast.success("Milestone marked as complete successfully");
      setIsMarkAsCompleteModalOpen(false);
    }
    setIsMarkAsCompleteApiCall(false);
  };

  return (
    <>
      <Header lg />
      {/* <section className="Dashboardpage-section"> */}
      <Container className="custom-container py">
        <Row>
          {/* <Col lg={3}>
            <SideNavBar />
          </Col> */}
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>

          <Col lg={9}>
            {isApiCall ? (
              <TableRowsLoader />
            ) : (
              <>
                <Row>
                  <Col lg={12}>
                    <div className="Dashboardpage-invoice">
                      {/* <h2>Detail</h2> */}
                      <div className="breedCum mt-4">
                        <span>
                          {loc == 1
                            ? "Assigned Projects"
                            : loc == 2
                            ? "Invoices"
                            : loc == 3
                            ? "Payments"
                            : loc == 4
                            ? "Notifications"
                            : loc == 5
                            ? "Assigned Projects"
                            : "My Project"}
                        </span>
                        <BiRightArrow className="ic" />
                        <span>Project Detail</span>
                      </div>
                      <p className={`f-f-popin-reg t-t-c`}>
                        Project Status:{" "}
                        <span className={`${detail?.projectStatus}`}>
                          {detail?.projectStatus?.replace(
                            formRegEx,
                            formRegExReplacer
                          )}
                        </span>
                      </p>

                      {/* Project Completemess */}
                      <div className="innerspan">
                        <span>
                          {" "}
                          {parseFloat(projectCompleteness?.value).toFixed(2)}%
                        </span>
                        <ProgressBar
                          variant={projectCompleteness?.class}
                          now={projectCompleteness?.value}
                          className={projectCompleteness?.class}
                          style={{
                            width: "150px",
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* if admin has ever make a proposal to freelancer */}
                {adminforFreelancer && (
                  <div className="customer_detail_main">
                    <Col md={12}>
                      <h3>Proposal Details</h3>
                    </Col>
                    <Col md={12}>
                      <div className="custmorDetail_inner">
                        <Col md={12}>
                          <p>
                            <span class="inner-text bd f-f-popin-med">
                              Title:{" "}
                            </span>
                            <span className="f-f-popin-med inner-text bd t-t-c">
                              {adminforFreelancer?.title}
                            </span>
                          </p>
                          <h5>Description:</h5>
                        </Col>
                        <Col md={12}>
                          <div className="custmorDetail_innerText">
                            <p className="small f-f-popin-reg">
                              {ReactHtmlParser(adminforFreelancer?.description)}
                            </p>
                            <p className="f-f-popin-reg">
                              <span className="inner-text bd">Amount: </span>
                              {detail?.currency &&
                                currencies[detail?.currency?.toUpperCase()]
                                  .symbol}
                              {/* {numeral(adminforFreelancer?.amount).format(
                                "0,00a"
                              )} */}
                              {numberWithCommas(adminforFreelancer?.amount)}
                            </p>
                            <p className="f-f-popin-reg">
                              <span className="inner-text bd">
                                Method Of Measurement :
                              </span>
                              {detail?.methodOfMeasurement?.map((item) => {
                                return <p>{item?.value}</p>;
                              })}
                            </p>
                            <p className="f-f-popin-reg t-t-c">
                              <span className="inner-text bd">
                                Proposal Status:{" "}
                              </span>
                              <span className={`${adminforFreelancer?.status}`}>
                                {adminforFreelancer?.status?.replace(
                                  formRegEx,
                                  formRegExReplacer
                                )}
                              </span>
                            </p>
                            <p className="f-f-popin-reg t-t-c">
                              <span className="inner-text bd">
                                Project End Date:{" "}
                              </span>
                              <span>{adminforFreelancer?.proposalDate}</span>
                            </p>
                          </div>
                        </Col>

                        {/* Buttons container */}
                        {["active", "accepted"].includes(
                          adminforFreelancer?.status
                        ) && (
                          <div>
                            {adminforFreelancer?.status == "active" && (
                              <>
                                <button
                                  className="btn-detail"
                                  onClick={handleProposalAcceptReejct.bind(
                                    this,
                                    "accepted"
                                  )}
                                  disabled={isAcceptingProposal}
                                >
                                  {isAcceptingProposal
                                    ? "Please wait..."
                                    : "Accept"}
                                </button>
                                <button
                                  className="btn-detail"
                                  // onClick={handleProposalAcceptReejct.bind(
                                  //   this,
                                  //   "rejected"
                                  // )}
                                  onClick={() =>
                                    setIsRejectionDetailModalOpen(true)
                                  }
                                  disabled={isRejectingProposal}
                                >
                                  {isRejectingProposal
                                    ? "Please Wait..."
                                    : "Reject"}
                                </button>
                              </>
                            )}

                            <button
                              className="btn-detail"
                              onClick={() =>
                                history.push({
                                  pathname: "/ChatScreen",
                                  state: {
                                    userId: adminforFreelancer?.sendTo?._id,
                                    projectId: adminforFreelancer?.projectId,
                                    proposalId: adminforFreelancer?._id,
                                  },
                                })
                              }
                            >
                              Chat With Admin
                            </button>
                          </div>
                        )}

                        {/* if rejected */}
                        {adminforFreelancer?.status == "rejected" && (
                          <>
                            <p className="text-danger">
                              This proposal has been rejected/closed.
                            </p>
                            <p>
                              <b>Reason: </b>
                              <ShowMoreShowLessText
                                text={adminforFreelancer?.rejectionReason}
                                visibility={200}
                              />
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </div>
                )}

                {/* if we have more than 1 milestone for freelancer */}
                {adminforFreelancer?.milestones?.length > 0 && (
                  <div className="detailPage_table mb-5">
                    <Col lg={12}>
                      <Row>
                        <Col lg={8} md={6} sm={12}>
                          <h3>Project Milestone</h3>
                        </Col>
                        {adminforFreelancer?.status != "active" && (
                          <Col lg={4} md={6} sm={12}>
                            <div className="freelancer_project_container">
                              <label className="bd">Days Left :</label>
                              <div className="freelancer_project">
                                <input
                                  type="text"
                                  placeholder="Days Left"
                                  className="daysLeftInp"
                                  value={daysLeft}
                                  onChange={(e) => setDaysLeft(e.target.value)}
                                />
                                <button
                                  className="btn-detail"
                                  onClick={() => SendNoOfDays()}
                                >
                                  {isdaysleftSend ? "wait..." : " Send"}
                                </button>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <div className="invoice-Table">
                        <table>
                          <thead className="invoice-head">
                            <tr>
                              <th>No.</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Budget</th>
                              {detail?.accecptedPorposalByFreelancer?.sendTo
                                ?._id == user?._id && (
                                <>
                                  <th>Status</th>
                                  <th>Paid (By Admin)</th>
                                  <th>Withdrawal Request (By You)</th>
                                  <th>Invoice</th>
                                  <th>Invoice Password</th>
                                  <th>Actions</th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {adminforFreelancer?.milestones?.map(
                              (item, index) => {
                                return (
                                  <tr className="invoice-row" key={item?._id}>
                                    <td>{++index}</td>
                                    <td>{item?.title}</td>
                                    <td>
                                      <ShowMoreShowLessText
                                        text={item?.description}
                                        visibility={8}
                                      />
                                    </td>
                                    <td>
                                      {detail?.currency &&
                                        currencies[
                                          detail?.currency?.toUpperCase()
                                        ].symbol}
                                      {/* ${numeral(item?.amount).format("0.0a")} */}
                                      {numberWithCommas(item?.amount)}
                                    </td>
                                    {detail?.accecptedPorposalByFreelancer
                                      ?.sendTo?._id == user?._id && (
                                      <>
                                        <td className={`t-t-c ${item?.status}`}>
                                          {item?.status?.replace(
                                            formRegEx,
                                            formRegExReplacer
                                          )}
                                        </td>
                                        <td>
                                          {item?.isMilestonePaid ? "Yes" : "No"}
                                        </td>
                                        <td>
                                          {item?.isMilestonePaid &&
                                          item?.makeWidthDrawlRequest ? (
                                            "Completed"
                                          ) : item?.status == "completed" &&
                                            !item?.makeWidthDrawlRequest ? (
                                            <button
                                              className="btn-detail"
                                              onClick={() => {
                                                releaseRequestHandler(
                                                  item?._id
                                                );
                                                // setIsRequestReleaseModalOpen(
                                                //   true
                                                // );
                                                // setReleaseMilestoneId(
                                                //   item?._id
                                                // );
                                              }}
                                              disabled={isMakingReleaseRequest}
                                            >
                                              {isMakingReleaseRequest
                                                ? "Please Wait..."
                                                : "Make Withdrawal Request"}
                                            </button>
                                          ) : item?.makeWidthDrawlRequest &&
                                            !item?.isMilestonePaid ? (
                                            "Withdrawal Request Sent"
                                          ) : (
                                            "None"
                                          )}
                                        </td>
                                        <td>
                                          {item?.invoice ? (
                                            <div
                                              className="c-p"
                                              onClick={() =>
                                                window.open(
                                                  `${pdfUrl}${item?.invoice}`,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <span>Open PDF</span>
                                              <FaFilePdf className="close-icon" />
                                              <span></span>
                                            </div>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {item?.pdfPassword ? (
                                            <CopyToClipboard
                                              text={item?.pdfPassword}
                                              onCopy={() =>
                                                toast.success(
                                                  "Password copied to clipboard."
                                                )
                                              }
                                            >
                                              <div className="c-p">
                                                <AiOutlinePaperClip
                                                  style={{ fontSize: "20px" }}
                                                />
                                                <span>(Click to Copy)</span>
                                              </div>
                                            </CopyToClipboard>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {item?.status == "pending" ? (
                                            <button
                                              className="btn-detail"
                                              onClick={() => {
                                                setIsMarkAsCompleteModalOpen(
                                                  true
                                                );
                                                setMarkAsCompleteData(item);
                                              }}
                                            >
                                              Mark as Complete
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </div>
                )}

                {/* Project Images */}
                {detail?.images?.length > 0 && (
                  <Row className="selectImg_box">
                    <Col md={12}>
                      <h5>Images</h5>
                    </Col>
                    {detail?.images.map((image, index) => (
                      <Col lg={4} key={index}>
                        <div
                          className="selectimgs c-p"
                          onClick={() =>
                            window.open(`${imageUrl}${image}`, "_blank")
                          }
                        >
                          <img src={`${imageUrl}${image}`} alt="project-img" />
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}

                {/* Project Docs */}
                {detail?.pdfs?.length > 0 && (
                  <Row className="selectImg_box">
                    <Col md={12}>
                      <h5>Drawings and Specifications</h5>
                    </Col>
                    {detail?.pdfs?.map((document, index) => (
                      <Col lg={4} key={index}>
                        <div
                          className="selectimgs select_pdf c-p"
                          onClick={() =>
                            window.open(`${pdfUrl}${document}`, "_blank")
                          }
                        >
                          <FaFilePdf className="pdf_icon" />
                          <p>Open PDF</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />

      {/* For Release Request */}
      {isRequestReleaseModalOpen && (
        <BankingDetailModal
          setOpen={setIsRequestReleaseModalOpen}
          open={isRequestReleaseModalOpen}
          onClick={(widthDrawlDetail) =>
            releaseRequestHandler(widthDrawlDetail)
          }
          isLoading={isMakingReleaseRequest}
        />
      )}
      {/* rejection reason modal */}
      {isRejectionDetailModalOpen && (
        <FreelancerRejectionModal
          setOpen={setIsRejectionDetailModalOpen}
          open={isRejectionDetailModalOpen}
          onClick={(reason) => handleProposalAcceptReejct("rejected", reason)}
          isLoading={isRejectingProposal}
        />
      )}

      {/* MArk Milestone as coplete */}
      <AreYouSureModal
        setOpen={setIsMarkAsCompleteModalOpen}
        open={isMarkAsCompleteModalOpen}
        onClick={markMiletoneAsCompleteHandler}
        title="Are you sure you want to mark this milestone as completed?"
        isLoading={isMarkAsCompleteApiCall}
      />
      {/* </section> */}

      {/* {isApiCall && <BodyLoader dark />} */}
    </>
  );
}
