import React, { useEffect, useState } from "react";
import classes from "./Blog.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Get } from "../../Axios/AxiosFunctions";
import BlogCard from "../../components/BlogCard/BlogCard";
import { useDispatch, useSelector } from "react-redux";
import Header from "./../../components/header";
import Footer from "./../../components/Footer";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import CardLoader from "../../components/CardLoader";
import NullDataComponent from './../../components/NullDataComponent';

const Blog = (props) => {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  let blogUrl = BaseURL(`users/blog?status=active`);

  const GetAllBlogData = async () => {
    setLoading(true);
    const responseData = await Get(blogUrl);
    if (responseData !== undefined) {
      const data = responseData?.data?.data;
      setBlogData([...data]);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAllBlogData();
  }, []);

  return (
    <div className={[classes.BlogMainSection].join(" ")}>
      <Header />
      
       <Container className={`${classes.blogCard_div} g-0`}>
          {loading ? (
            <CardLoader size={400} smbox={false} />
          ) : (
            <Row className="g-0">
              {blogData.map((item, index) => {
                return (
                  <Col md={12}>
                    <BlogCard item={item} index={index} key={index} />{" "}
                  </Col>
                );
              })}
            </Row>
          )}
           {(!loading && blogData.length === 0) && (
                <div className="p-d-null-data">
                  <NullDataComponent
                    c2
                    text={"No Blog Found."}
                  />
                </div>
              )}
        </Container>
      <Footer />
    </div>
  );
};

export default Blog;
