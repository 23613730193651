import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo1 from "../assets/images/3.png";
import logo2 from "../assets/images/4.png";
import logo3 from "../assets/images/6.png";
import logo4 from "../assets/images/2.png";
import logo5 from "../assets/images/5.png";
import logo6 from "../assets/images/1 (2).png";
import "../assets/styles/BrandsLogo.css";
import { imageUrl } from "../Config/apiUrl";

function BrandsLogo({ data }) {
  return (
    <>
      <section className="brandsLogo-section">
        <Container>
          <Row>
            {data?.company?.map((item) => {
              return (
                <Col lg={2} md={4} sm={4} xs={6}>
                  <div className="logos">
                    <img src={`${imageUrl}${item?.image}`} alt="logo1" />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default BrandsLogo;
