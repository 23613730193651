import React, { useEffect, useRef, useState } from "react";
import classes from "./BlogCard.module.css";
// import { useNavigate } from "react-router-dom";
// import { ProfileImg } from "../../constant/imagePath";
// import { Button } from "../Button/Button";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { imageUrl } from './../../Config/apiUrl';
import { isMobileViewHook } from "../../hooks/IsMobileViewHook";
import ReactHtmlParser from "react-html-parser";

function BlogCard({ isButton = true, index, item }) {
  const history = useHistory()
  const [isMobile, setIsMobile] = useState(false);

  // const navigate = useNavigate();
  // const id = item?._id;

  useEffect(() => {
    isMobileViewHook(setIsMobile, 991);
  }, []);

  
  const RenderContent = () => {
    return (
      <div
        className={`${classes.blogCardContainer}`}
        style={{
          paddingLeft: index % 2 == 0 ? "5%" : "0%",
        }}
      >
        <h3 className="t-t-c">{item?.title}</h3>
        <div className={`${classes.blogCardDateContainer}`}>
          <span className={classes.blogDate}>{moment(item?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
        </div>
        <div className={`${classes.ellipsis} ${classes.text}`}>
        <p className={`p-18 ${classes.description_para}`}>
          {ReactHtmlParser(item?.description)}
        </p>
        </div>
       
        {isButton && (
          <button
          className={`${classes.btn1} ${classes.invoice_btn}`}
          onClick={() => {
            history.push(`/blog/${item?._id}`)
          }}
          >
            Learn More
          </button>
        )}
      </div>
    );
  };

  return (
    <Container  className={`g-0 ${classes.blogCardMainContainer}`}>
      <Row className="g-0">
        {(index % 2 == 1 && isMobile == false) && (
          <Col md={12} lg={6}>
            <RenderContent />
          </Col>
        )}
        <Col md={12} lg={6}>
          <div className={`${classes.blogCardImageContainer}`}>
            <img src={`${imageUrl}${item?.image}`} alt="blogCardImage" />
          </div>
        </Col>
        {(index % 2 == 0 || isMobile) && (
          <Col md={12} lg={6}>
            <RenderContent />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default BlogCard;

// import React, { useEffect, useRef, useState } from "react";
// import classes from "./BlogCard.module.css";
// // import { useNavigate } from "react-router-dom";
// // import { ProfileImg } from "../../constant/imagePath";
// // import { Button } from "../Button/Button";
// import moment from "moment";
// import { Col, Container, Row } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
// import { imageUrl } from "./../../Config/apiUrl";
// function BlogCard({ isButton = true, index, item }) {
//   console.log("==========>itemid", item?._id);
//   const history = useHistory();
//   // const navigate = useNavigate();
//   // const id = item?._id;

//   const RenderContent = () => {
//     return (
//       <div
//         className={`${classes.blogCardContainer}`}
//         style={{
//           paddingLeft: index % 2 == 0 ? "5%" : "0%",
//         }}
//       >
//         <h3 className="t-t-c">{item?.title}</h3>
//         <div className={`${classes.blogCardDateContainer}`}>
//           <span className=".p-18">
//             {moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
//           </span>
//         </div>
//         <p className=".p-18">{item?.description}</p>
//         {isButton && (
//           <button
//             className={`${classes.btn1} ${classes.invoice_btn}`}
//             onClick={() => {
//               history.push(`/blog/${item?._id}`);
//             }}
//           >
//             Learn More
//           </button>
//         )}
//       </div>
//     );
//   };

//   return (
//     <Container className={`g-0 ${classes.blogCardMainContainer}`}>
//       <Row className="g-0">
//         {index % 2 == 1 && (
//           <>
//             <Col md={{span:6, order:(index+1) }}>
//               <div className={`${classes.blogCardImageContainer}`}>
//                 <img src={`${imageUrl}${item?.image}`} alt="blogCardImage" />
//               </div>
//             </Col>
//             <Col md={{span:6, order:index }}>
//               <RenderContent />
//             </Col>
//           </>
//         )}
//         {index % 2 == 0 && (
//           <>
//             <Col md={{span:6, order:index }}>
//               <div className={`${classes.blogCardImageContainer}`}>
//                 <img src={`${imageUrl}${item?.image}`} alt="blogCardImage" />
//               </div>
//             </Col>
//             <Col  md={{span:6, order:(index+1) }}>
//               <RenderContent />
//             </Col>

//           </>
//         )}
//       </Row>
//     </Container>
//   );
// }

// export default BlogCard;
