import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { apiHeader, BaseURL, imageUrl } from "../Config/apiUrl";
export default function PagesSection1(data) {
  
    
  return (
    <>
    <style>{`.Servicespage-section1{
      background-image:url(${imageUrl + data?.data?.sec1CoverImage})
  }`}</style>
    <section className="Servicespage-section1">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="Servicespage-section1-heading">
              <h1>{data?.data?.sec1Heading}</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}
