import React, { useState, useRef } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";
import {
  apiHeader,
  BaseURL,
  fallbackUser,
  imageUrl,
  validateEmail,
} from "../Config/apiUrl";
import { Patch, Post } from "../Axios/AxiosFunctions";
import { AiOutlineCamera } from "react-icons/ai";
import { useHistory } from "react-router-dom";

import { AiFillLock } from "react-icons/ai";
import { HiInformationCircle } from "react-icons/hi";

import { useDispatch, useSelector } from "react-redux";
import { signOutRequest, updateUser } from "../store/Actions/authAction";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";

export default function EditProfileModal({ showModal, closeModal }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  console.log(user, "user user user");
  const [email, setEmail] = useState(user?.email);
  const [name, setName] = useState(user?.name);
  const [bankDetails, setBankDetails] = useState(user?.bankDetails);
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  //new fields in Doc for Customer
  const [company, setCompany] = useState(user?.company);
  const [job, setJob] = useState(user?.job);
  const [address, setAddress] = useState(user?.address);
  const [companyWebsite, setCompanyWebsite] = useState(user?.companyWebsite);
  //new fields in Doc for Freelancer
  const [age, setAge] = useState(user?.age);
  const [qualification, setQualification] = useState(user?.qualification);

  // handleSubmit
  const handleSubmit = async () => {
    if (name.trim() == "") return toast.error("Name is required");
    if (user?.role === "freelancer" && bankDetails?.trim() === "")
      return toast.error("Please enter your banking details.");

    const params = {
      name: name,
      ...(user?.role == "customer" && {
        company,
        job,
        address,
        companyWebsite,
      }),
      ...(imageFile !== null && { photo: imageFile }),
      ...(user?.role === "freelancer" && { bankDetails ,age,qualification}),
    };
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    const url = BaseURL("users/updateMe");
    setIsLoading(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsLoading(false);

    if (response !== undefined) {
      dispatch(updateUser(response?.data?.data?.user));
      closeModal();
      toast.success("Profile Updated Successfully.");
    }
  };

  // handlePasswordDelete
  const handlePasswordDelete = async (e) => {
    e.preventDefault();
    if (password?.length < 8)
      return toast.warn("Please enter your complete password.");
    const params = { password };

    const url =
      user?.role === "freelancer"
        ? BaseURL(`freelancer/delete-my-account`)
        : BaseURL(`customer/delete-my-account`);

    setIsDeleteApiCall(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      toast.success("Your account has been deleted successfully.");
      dispatch(signOutRequest());
      history("/");
    }
  };

  return (
    <>
      <div className="editProfile">
        <Modal
          show={showModal}
          onHide={() => {
            closeModal();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="editProfile_form">
              {/* Photo */}
              <Row className="d-flex img-main-c">
                <Col md={12}>
                  {typeof imageFile === "object" && imageFile !== null ? (
                    <div className="cameraicon m-auto">
                      <img src={URL.createObjectURL(imageFile)} />
                    </div>
                  ) : imageFile !== "" ? (
                    <div className="cameraicon m-auto">
                      <img
                        src={
                          user?.photo == "default.jpg" ||
                          user?.photo == undefined
                            ? fallbackUser
                            : `${imageUrl}${user?.photo}`
                        }
                      />
                    </div>
                  ) : (
                    <div className="cameraicon m-auto">
                      <AiOutlineCamera className="CameraAltIcon" />
                    </div>
                  )}
                </Col>

                <Col md={12}>
                  <div className="text-left ms-4 UploadProfile">
                    <label
                      class="setting_fileupload "
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      <div className="setting-innerDiv_fileUpload ">
                        <div className="d-flex j-center a-center  c-p">
                          <span>Upload Image</span>
                        </div>
                      </div>
                    </label>
                    <input
                      ref={inputRef}
                      type="file"
                      size="2000000"
                      className="file_upload_form3rd"
                      onChange={(e) => {
                        if (e.target.files?.length > 0) {
                          if (
                            !["image/jpeg", "image/png", "image/jpg"].includes(
                              e.target.files[0].type
                            )
                          ) {
                            return toast.error(
                              "Please upload a valid image. [jpg and png formats only]"
                            );
                          }
                        }
                        setImageFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              {/* Email */}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label
                  style={{
                    margin: "10px 60px",
                  }}
                >
                  Email
                </Form.Label>
                <Form.Control
                  className="input-box siginInputbox"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete={false}
                  disabled
                />
              </Form.Group>
              {/* Name */}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label
                  style={{
                    margin: "10px 60px",
                  }}
                >
                  User Name
                </Form.Label>
                <Form.Control
                  className="input-box siginInputbox"
                  type="text"
                  placeholder="User Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete={false}
                />
              </Form.Group>
              {/* Bank Details */}

              {user?.role == "customer" && (
                <>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Company
                    </Form.Label>

                    <div className="p-r">
                      <Form.Control
                        className="input-box siginInputbox"
                        type={"text"}
                        placeholder="Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Job
                    </Form.Label>

                    <div className="p-r">
                      <Form.Control
                        className="input-box siginInputbox"
                        type={"text"}
                        placeholder="Job"
                        value={job}
                        onChange={(e) => setJob(e.target.value)}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Address
                    </Form.Label>

                    <div className="p-r">
                      <Form.Control
                        className="input-box siginInputbox"
                        type={"text"}
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Company Website
                    </Form.Label>

                    <div className="p-r">
                      <Form.Control
                        className="input-box siginInputbox"
                        type={"text"}
                        placeholder="Company Website"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                </>
              )}

              {user?.role === "freelancer" && (
                <>
         
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Age
                    </Form.Label>

                    <div className="p-r">
                      <Form.Control
                        className="input-box siginInputbox"
                        type={"text"}
                        placeholder="Age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Qualification
                    </Form.Label>

                    <div className="p-r">
                      <Form.Control
                        className="input-box siginInputbox"
                        type={"text"}
                        placeholder="Qualification"
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex justify-content-between">
                    <Form.Label
                      style={{
                        margin: "10px 60px",
                      }}
                    >
                      Bank Details
                    </Form.Label>
                    <div className="payment-info">
                      <Tooltip
                        placement="left"
                        title="Add your payment details i.e. your IBAN number | Bank account number | Paypal etc.  Information here would be Bank Name, Bank Address, bank AC Number, Sort Code and BIC"
                      >
                        <span>
                          <HiInformationCircle />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="p-r">
                    <Form.Control
                      className="bank-detail-area secure-input"
                      type="text"
                      as="textarea"
                      placeholder="Bank details here..."
                      value={bankDetails}
                      onChange={(e) => setBankDetails(e.target.value)}
                      autoComplete={false}
                      rows={4}
                    />
                    <AiFillLock className="secureInputLock" />
                  </div>





                </Form.Group>

                </>

              )}

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label
                  style={{
                    margin: "10px 60px",
                  }}
                >
                  Delete Account
                </Form.Label>

                <div className="p-r">
                  <Form.Control
                    className="input-box siginInputbox"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Type your password in order to delete your account."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="passwordeye"
                    onClick={() => setIsPasswordVisible((p) => !p)}
                  >
                    {isPasswordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                </div>
                <button
                  className="btn-detail delete-account-btn"
                  onClick={handlePasswordDelete}
                  disabled={isDeleteApiCall}
                >
                  {isDeleteApiCall
                    ? "Deleting Account..."
                    : "Delete My Account"}
                </button>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-x">
              <button
                className="btn-detail"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? "Please Wait..." : `Submit`}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
