import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ModalSkeleton from "../components/ModalSkeleton";

function FreelancerRejectionModal({ open, setOpen, onClick, isLoading }) {
  const [description, setDescription] = React.useState("");

  return (
    <ModalSkeleton open={open} setOpen={() => setOpen(false)} maxContent>
      <section className="are-you-sure-modal">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="modal-box">
                <h5>Tell us why you are rejecting this proposal.</h5>

                <Col lg={12} md={12}>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter here..."
                    rows={6}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <div className="btns-box">
                  {/* <button
                    className="no-btn"
                    disabled={isLoading}
                    onClick={() => setOpen(false)}
                  >
                    No
                  </button> */}
                  <button
                    className="yes-btn"
                    disabled={isLoading}
                    onClick={() => {
                      if (description == "")
                        return toast.warn("Please enter rejection reason");

                      onClick(description);
                    }}
                  >
                    {isLoading ? "Please Wait....." : "Send"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </ModalSkeleton>
  );
}

export default FreelancerRejectionModal;
