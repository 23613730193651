import React, { useEffect, useState } from "react";
import classes from "./BlogDetail.module.css";
import { Container, Row ,Col } from "react-bootstrap";

import parse from "html-react-parser";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
// import { Loader } from "../../stories/Loader";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./../../components/header";
import Footer from "./../../components/Footer";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TableRowsLoader from './../../components/TableRowsLoader';
import CardLoader from './../../components/CardLoader';
import ReactHtmlParser from "react-html-parser";

const BlogDetail = (props) => {
  const params = useParams();
  const history = useHistory();

  //  const state = useLocation();

  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);
  // console.log("=============blogData",blogData)
  // get data
  const GetBlogData = async function () {
    const id = params?.id;

    if (!id) return history.goBack();

    let blogUrl = BaseURL(`users/blog/${id}`);
    setLoading(true);
    const responseData = await Get(blogUrl);
    
    if (responseData !== undefined) {
      setBlogData(responseData?.data?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    GetBlogData();
  }, []);

  return (
    <div className={[classes.BlogMainSection].join(" ")}>
      <Header />
      <Container className="g-0">
          <Row className="g-0">
            {
              loading ? (
                  <CardLoader size={400} smbox={false}/>
              ):(
                <Col lg={12}>
            <div className={`${classes.blogDetail_image}`}>
             <img src={`${imageUrl}${blogData?.image}`} />
            </div>
            </Col>
              )
            }
           {
            loading ? (
              <TableRowsLoader tableText={false} />
          ):(
            <Col lg={12}>
            <div className={`${classes.blogDetailHeading}`}>
            <h2 className={[classes.header].join(" ")}>{blogData?.title}</h2>
            <p className={`${classes.date_blogDetail}`}>{moment(blogData?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
            <div className={`${classes.blogDetail_text}`}>
              <p>
                {/* {blogData?.description} */}
                {ReactHtmlParser(blogData?.description)}
                 </p>
            </div>
            </div>
            </Col>
          )
           }
            
          </Row>
        </Container>
      
      <Footer />
    </div>
  );
};

export default BlogDetail;
