import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Orderimg1 from "../assets/images/undraw_add_files_re_v09g (1).png";
import Orderimg2 from "../assets/images/undraw_data_report_re_p4so.png";
import Orderimg3 from "../assets/images/undraw_stripe_payments_re_chlm.png";
import Orderimg4 from "../assets/images/undraw_receipt_re_fre3.png";
import imgcricle from "../assets/images/Ellipse 27.png";
import Lineimg from "../assets/images/Line 2.png";
import "../assets/styles/HowToOrder.css";
import { imageUrl } from "../Config/apiUrl";

export default function HowToOrder({ data }) {
  return (
    <>
      <section className="Order">
        <Container style={{marginTop:"100px"}}>
          <Row>
            <Col lg={12}>
              <div className="orderSection">
                <h1 data-aos="fade-up">
                  {data?.sec5Heading}
                  {/* How To Order */}
                </h1>
              </div>
            </Col>
          </Row>
          <Row className="line">
            {/* <div className="Ordersection-line">
              <img src={Lineimg} alt="img" />
            </div> */}
            {data["how-to-order"]?.map((item, index) => {
              if (index % 2 != 0) {
                return (
                  <>
                    <Col lg={5}>
                      <div className="OrderContent">
                        <h2>{item?.title}</h2>
                        <p className="mid">{item?.description}</p>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="circleImg ">
                        <img src={imgcricle} alt="img" />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="OrderImg order-img-box" >
                        <img
                          src={`${imageUrl}${item?.image}`}
                          alt="orderimg1"
                        />
                      </div>
                    </Col>
                  </>
                );
              } else {
                return (
                  <>
                    <Col lg={5}>
                      <div className="OrderImg order-img-box" >
                        <img
                          src={`${imageUrl}${item?.image}`}
                          alt="orderimg1"
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="circleImg ">
                        <img src={imgcricle} alt="img" />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="OrderContent">
                        <h2>{item?.title}</h2>
                        <p className="mid">{item?.description}</p>
                      </div>
                    </Col>
                  </>
                );
              }
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}
