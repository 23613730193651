import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerLogo from "../assets/images/whiteLogi.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl } from "../Config/apiUrl";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/Footer.css";
import ShowMoreShowLessText from "./ShowMoreShowLessText";

export default function Footer() {
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [apiData, setApiData] = useState({});

  async function getData() {
    const url = BaseURL(`cms/page/footer`);
    setIsApiCall(true);
    const response = await Get(url, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setApiData(data);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="Footer-section">
        <Container className="Footer-container">
          <Row>
            <Col md={5} sm={6} xs={12} className="latestNews-768">
              <div className="footer-leftside">
                <h3 style={{
                  color: "white",
                }}>
                  Demo
                </h3>
                {/* <img
                  src={footerLogo}
                  alt="footerLogo"
                  style={{
                    width: "199px",
                  }}
                /> */}

                <p className="mid">
                  <ShowMoreShowLessText
                    text={apiData?.description}
                    visibility={100}
                  />
                </p>
                <div className="icons-div">
                  {apiData?.socialIcons?.map((item, index) => (
                    <div className="social-icons-new c-p">
                      <a target={"_blank"} href={item?.socialUrl}>
                        <img src={`${imageUrl}${item?.image}`} />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col md={4} sm={6} xs={12} className="latestNews-768">
              <div className="footer-middile">
                <h2>Quick Links</h2>
                <ul>
                  <li onClick={() => history.push("/")}>
                    <span>Home</span>
                  </li>
                  <li onClick={() => history.push("/add-project")}>
                    <span>Add Project</span>
                  </li>
                  <li onClick={() => history.push("/services")}>
                    <span>Services</span>
                  </li>
                  <li onClick={() => history.push("/order")}>
                    <span>Order</span>
                  </li>
                  {/* <li><a href="/">FAQ</a></li> */}
                  <li onClick={() => history.push("/aboutus")}>
                    <span>About Us</span>
                  </li>
                  <li onClick={() => history.push("/contactus")}>
                    <span>Contact Us</span>
                  </li>
                  <li onClick={() => history.push("/support")}>
                    <span>Terms & Conditions</span>
                  </li>
                  <li onClick={() => history.push("/privacyPolicy")}>
                    <span>Privacy Policy</span>
                  </li>
                  <li onClick={() => history.push("/privacyPolicy2")}>
                    <span>Protection Policy</span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} sm={6} xs={12} className="latestNews-768">
              <div className="footer-rightside">
                <h2>{apiData?.contactDetails?.sec1Heading}</h2>
                <span>
                  <a href={`mailto:${apiData?.contactDetails?.email}`}>
                    <HiOutlineMail className="social-icons" />
                    {apiData?.contactDetails?.email}
                  </a>
                </span>{" "}
                <br />
                <div className="con">
                  <div className="phone_num">
                    <span className="h-100 d-inline-block"></span>
                    <span className="d-inline-block">
                      <a href={`tel:+${apiData?.contactDetails?.mobileNo}`}>
                        <BsFillTelephoneFill className="social-icons" />{" "}
                        {`${apiData?.contactDetails?.mobileNo}(Uk)`}
                      </a>
                      <br />
                      <a
                        style={{
                          marginLeft: "53px",
                        }}
                        href={`tel:+${apiData?.contactDetails?.phoneNo}`}
                      >{`${apiData?.contactDetails?.phoneNo}(IRL)`}</a>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="copyright-text">
                <p className="mid">{apiData?.copyright}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
