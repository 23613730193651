import React, { useState, useEffect, } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import "../assets//styles/Dashboardpage.css";
import dashboardimg1 from "../assets/images/dashboard.png";
import dashboardimg2 from "../assets/images/Group 550.png";
import dashboardimg3 from "../assets/images/Group 551.png";
import dashboardimg4 from "../assets/images/Group 552.png";
import dashboardimg5 from "../assets/images/Group 553.png";
import dashboardimg6 from "../assets/images/Group 554.png";
import addProject from "../assets/images/collection-add.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BaseURL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
const ResponsiveMenu = () => {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const role = user?.role;
  const history = useHistory();
  const location = useLocation().pathname;

   // get noti Count
   const [notiCount, setNotiCount] = useState(0);
   const getNoticount = async () => {
     const url = BaseURL("notifications/all?limit=1&page=1");
     const response = await Get(url, accessToken, false);
     if (response !== undefined) {
       setNotiCount(response.data?.newNotifications);
     }
   };
 
   useEffect(() => {
     getNoticount();
   }, [window.location.pathname]);
 

  return (
    <div className="responsiveSideBarMenu">
     <ul>
          <li
              className={`custom-btn memuBar ${location === "/dashboard" && "responsiveActive-link"
                }`}
              onClick={() => {
                if (!accessToken) return;
                history.push("/dashboard");
              }}
            >
              {" "}
              <span>
                <img src={dashboardimg1} alt="dashboardimg" />
              </span>{" "}
              Dashboard
            </li>


        {/* <li>
          <a href="/" className="custom-btn responsiveActive-link">
            <span className="icon-img">
              <img src={dashboardimg2} alt="dashboardimg" />
            </span>
            My Proposals
          </a>
        </li> */}
         {role == "customer" && (
              <li
                className={`custom-btn memuBar ${location === "/add-project" && "responsiveActive-link"
                  }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/add-project");
                }}
              >
                {" "}
                <span className="icon-img">
                  <img src={addProject} alt="dashboardimg" />
                </span>
                AddProjects
              </li>
            )}

        {/* <li>
          <a href="/" className="custom-btn">
            {" "}
            <span className="icon-img">
              <img src={dashboardimg2} alt="dashboardimg" />
            </span>
            My Projects
          </a>
        </li> */}
        {role == "customer" && (
              <li
                className={`custom-btn memuBar ${location === "/myproject" && "responsiveActive-link"
                  }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/myproject");
                }}
              >
                {" "}
                <span className="icon-img">
                  <img src={dashboardimg2} alt="dashboardimg" />
                </span>
                MyProjects
              </li>
            )}
        {/* <li>
          <a href="/" className="custom-btn">
            <span>
              <img src={dashboardimg3} alt="dashboardimg" />
            </span>
            Assign Project
          </a>
        </li> */}
         {role == "customer" && (
              <li
                className={`custom-btn memuBar ${location === "/Invoice" && "responsiveActive-link"
                  }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/Invoice");
                }}
              >
                {" "}
                <span className="icon-img">
                  <img src={dashboardimg3} alt="dashboardimg" />
                </span>
                Invoices
              </li>
            )}
        {/* <li>
          <a href="/" className="custom-btn">
            <span>
              <img src={dashboardimg4} alt="dashboardimg" />
            </span>
            Payments
          </a>
        </li> */}
         {role == "freelancer" && (
              <li
                className={`custom-btn memuBar ${location === "/AssignedProject" && "responsiveActive-link"
                  }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/AssignedProject");
                }}
              >
                <span>
                  <img src={dashboardimg3} alt="dashboardimg" />
                </span>
                AssignProject
              </li>
            )}
            {(role == "freelancer" || role == "customer") && (
              <li
                className={`custom-btn memuBar ${location === "/payment" && "responsiveActive-link"
                  }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/payment");
                }}
              >
                <span>
                  <img src={dashboardimg4} alt="dashboardimg" />
                </span>
                Payments
              </li>
            )}
             {(role == "freelancer" || role == "customer") && (
              <li
                className={`custom-btn memuBar ${location === "/notification" && "responsiveActive-link"
                  }`}
                onClick={() => {
                  if (!accessToken) return;
                  history.push("/notification");
                }}
              >
                <span>
                  <img src={dashboardimg6} alt="dashboardimg" />
                </span>
                {`Notification${notiCount > 0 ? "s" : ""}  `}
                <sup>{notiCount > 0 ? `(${notiCount > 99 ? "99+" : notiCount})` : ""}</sup>
              </li>
            )}

        {/* <li>
          <a href="/" className="custom-btn">
            <span>
              <img src={dashboardimg5} alt="dashboardimg" />
            </span>
            Messages
          </a>
        </li> */}
        {/* <li>
          <a href="/" className="custom-btn">
            <span>
              <img src={dashboardimg6} alt="dashboardimg" />
            </span>
            Notification
          </a>
        </li> */}
      </ul>
    </div>
  )
}

export default ResponsiveMenu