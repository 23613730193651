import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";
import { validateEmail } from "../Config/apiUrl";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function AddProjectSignUpModal({
  showModal,
  closeModal,
  handleSubmit,
  isLoading,
}) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [deviceId, setDeviceId] = useState("122323434");
  const [phoneNo, setPhoneNo] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);

  // handleGaherdata
  const handleGaherdata = async () => {
    const params = {
      email,
      name,
      password,
      passwordConfirm,
      deviceId,
      contactNo: phoneNo.replaceAll(" ", "").replaceAll("-", ""),
      role: "customer",
    };

    for (let key in params) {
      if (!params[key] || params[key] == "") {
        toast.error(`${key} is required`);
        return;
      }
    }

    if (!validateEmail(email)) {
      toast.error(`${email} is not valid`);
      return;
    }

    if (password !== passwordConfirm) {
      toast.error("Password and Confirm Password does not match");
      return;
    }

    if (
      !validator.isMobilePhone(phoneNo.replaceAll(" ", "").replaceAll("-", ""))
    )
      return toast.warn("Your mobile number is not valid.");

    await handleSubmit(params);
    setEmail("");
    setName("");
    setPassword("");
    setPasswordConfirm("");
    setPhoneNo("");
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Your Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                className="input-box siginInputbox"
                type="text"
                placeholder="Username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete={false}
              />
              <Form.Control
                className="input-box siginInputbox"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete={false}
              />
              <ReactPhoneInput
                containerClass="phoneContainerM"
                enableSearchField
                value={phoneNo}
                onChange={(phone) => setPhoneNo(phone)}
              />
              <div className="p-r">
                <Form.Control
                  className="input-box siginInputbox"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-eye r-10"
                  onClick={() => setIsPasswordVisible((p) => !p)}
                >
                  {isPasswordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </span>
              </div>
              <div className="p-r">
                <Form.Control
                  className="input-box siginInputbox"
                  type={isRePasswordVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <span
                  className="password-eye r-10"
                  onClick={() => setIsRePasswordVisible((p) => !p)}
                >
                  {isRePasswordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </span>
              </div>
              {/* <div className="SignUp-check">
                <Form.Check
                  inline
                  label="I agree"
                  name="I-agree"
                  type="checkbox"
                  isValid
                />
                <span className="big green">Terms & CondItions</span>
              </div> */}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-x">
            <button
              className="btn-detail"
              disabled={isLoading}
              onClick={handleGaherdata}
            >
              {isLoading ? "Please Wait..." : `Submit`}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
