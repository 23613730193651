import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import {
  formRegExReplacer,
  formRegEx,
  BaseURL,
  recordsLimit,
} from "../Config/apiUrl";
import SideNavBar from "../components/SideNavBar";
import ProjectCards from "../components/ProjectCards";
import { Get } from "../Axios/AxiosFunctions";
import Header from "../components/header";
import NullDataComponent from "../components/NullDataComponent";
import BodyLoader from "../components/BodyLoader";
import PaginationB from "../components/PaginationB";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import CardLoader from "../components/CardLoader";

let isInitial = true;

function MyProject() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [projects, setProjects] = useState([]);

  const [status, setStatus] = useState("all");

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // get Data
  async function getData(_page) {
    const url = BaseURL(
      `customer/myProjects?limit=${recordsLimit}&page=${_page}&status=${status}`
    );
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setProjects(data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData(page);
  }, [page]);

  useEffect(() => {
    if (!isInitial) {
      getData(1);
    }
    isInitial = false;
  }, [status]);
  // console.log(isApiCall, "========>>>>>>>");
  useEffect(() => {
    return () => {
      isInitial = true;
    };
  }, []);

  return (
    <>
      <Header lg />
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col lg={9}>
            <div className="df-jc-ac">
              <h2 className="py-4">My Projects</h2>
              <select
                className="dropdown-style-x"
                onChange={(e) => setStatus(e?.target?.value)}
                value={status}
              >
                {[
                  "all",
                  "pending",
                  "underReview",
                  "inProgress",
                  "completed",
                ].map((e) => (
                  <option key={e} value={e}>
                    {e.replace(formRegEx, formRegExReplacer)}
                  </option>
                ))}
              </select>
            </div>
            <Row className="myproject-row">
              {isApiCall &&
                [1, 2, 3, 4, 5, 6].map((_) => {
                  return (
                    <Col md={4} className="MyprojectCard-container">
                      <div className="MyprojectCard-box">
                        <CardLoader size={400} />
                      </div>
                    </Col>
                  );
                })}

              {projects?.length > 0 &&
                !isApiCall &&
                projects.map((items, index) => {
                  return (
                    <Col md={4} className="MyprojectCard-container">
                      <div className="MyprojectCard-box">
                        <ProjectCards
                          isApiCall={isApiCall}
                          key={index}
                          currency={items?.currency}
                          title={items?.title}
                          description={items?.description}
                          amount={items?.amount}
                          timeline={items?.timeline}
                          projectStatus={items?.projectStatus}
                          createDate={items?.createdAt}
                          onClick={() =>
                            history.push({
                              pathname: "/detailPage",
                              state: items?._id,
                              search: "loc=1",
                            })
                          }
                          cus_chat={items?._id}
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>

            <Col md={12}>
              {projects.length === 0 && !isApiCall && (
                <div className="p-d-null-data mt-25-vp">
                  <NullDataComponent
                    c1
                    text={isApiCall ? "Please Wait..." : "No Project Found."}
                  />
                </div>
              )}
            </Col>
            {totalCount > 1 && (
              <PaginationB
                page={page}
                setPage={setPage}
                totalCount={totalCount}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
      {/* {isApiCall && <BodyLoader />} */}
    </>
  );
}

export default MyProject;
