import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NotificationBox from "../components/NotificationBox";
import { BaseURL, recordsLimit } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import BodyLoader from "../components/BodyLoader";
import Header from "../components/header";
import SideNavBar from "../components/SideNavBar";
import NullDataComponent from "../components/NullDataComponent";
import PaginationB from "../components/PaginationB";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import TableRowsLoader from "../components/TableRowsLoader";
import { toast } from "react-toastify";

const ChatNotifications = () => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  async function getData(_page) {
    const url = BaseURL(
      `notifications/all?limit=${recordsLimit}&page=${_page}&isMessage=true`
    );
    setIsApiCall(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const data = response?.data?.data;
      setNotificationData(data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
    setIsApiCall(false);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData(page);
  }, [page]);

  // markAllAsReadHandler
  const markAllAsReadHandler = async () => {
    const url = BaseURL("notifications/seen/all");

    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setPage(1);
      await getData(1);
      toast.success("Notifications have been marked as read.");
    }
  };

  return (
    <>
      <Header lg />
      {/* <section className="Notification-section"> */}
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col md={9}>
            <div>
              <div className="Notification-heading mt-3 d-flex justify-content-between">
                <h3>Chat Alerts</h3>
                <h3
                  className={"markasReadBtmn"}
                  onClick={() => markAllAsReadHandler()}
                >
                  {isApiCall ? "Please Wait..." : "Mark All as Read"}
                </h3>
              </div>

              {isApiCall ? (
                <TableRowsLoader />
              ) : (
                <>
                  {notificationData?.map((e, i) => (
                    <NotificationBox key={i} data={e} />
                  ))}
                </>
              )}
              {totalCount > 1 && (
                <PaginationB
                  page={page}
                  setPage={setPage}
                  totalCount={totalCount}
                />
              )}

              {notificationData.length === 0 && !isApiCall && (
                <div className="p-d-null-data mt-25-vp">
                  <NullDataComponent c5 text={"No Chat Alert yet."} />
                </div>
              )}

              {/* {isApiCall && <BodyLoader />} */}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
      {/* </section> */}
    </>
  );
};

export default ChatNotifications;
