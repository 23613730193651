import React, { useState, useRef } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";
import {
  apiHeader,
  BaseURL,
  fallbackUser,
  formRegEx,
  formRegExReplacer,
  validateEmail,
} from "../Config/apiUrl";
import { Patch, Post } from "../Axios/AxiosFunctions";
import { AiOutlineCamera } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { saveLoginUserData, updateUser } from "../store/Actions/authAction";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function UpdatePasswordModal({ showModal, closeModal }) {
  const dispatch = useDispatch();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);
  const [isCurentPasswordVisible, setIsCurentPasswordVisible] = useState(false);
  // handleSubmit
  const handleSubmit = async () => {
    const params = {
      passwordCurrent,
      password,
      passwordConfirm,
    };

    // validate
    for (let key in params)
      if (params[key].trim() === "")
        return toast.error(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );

    if (password.length < 8)
      return toast.error("New password must be at least 8 characters long.");

    if (password !== passwordConfirm)
      return toast.error("Password and Confirm Password must be same.");

    const url = BaseURL("users/updateMyPassword");
    setIsLoading(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsLoading(false);

    if (response !== undefined) {
      dispatch(saveLoginUserData(response.data));
      closeModal();
      toast.success("Password Changed Successfully.");
      setPasswordCurrent("");
      setPassword("");
      setPasswordConfirm("");
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="change_password_modal">
            {/* Current Password */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                style={{
                  margin: "10px 60px",
                }}
              >
                Current Password
              </Form.Label>

              <div className="p-r">
                <Form.Control
                  className="input-box siginInputbox"
                  type={isCurentPasswordVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={passwordCurrent}
                  autoComplete={false}
                  onChange={(e) => setPasswordCurrent(e.target.value)}
                />
                <span
                  className="password-eye"
                  onClick={() => setIsCurentPasswordVisible((p) => !p)}
                >
                  {isCurentPasswordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </span>
              </div>
            </Form.Group>
            {/* New Password */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                style={{
                  margin: "10px 60px",
                }}
              >
                New Password
              </Form.Label>
              <div className="p-r">
                <Form.Control
                  className="input-box siginInputbox"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-eye"
                  onClick={() => setIsPasswordVisible((p) => !p)}
                >
                  {isPasswordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </span>
              </div>
            </Form.Group>

            {/* Confirm New Password */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                style={{
                  margin: "10px 60px",
                }}
              >
                Confirm New Password
              </Form.Label>

              <div className="p-r">
                <Form.Control
                  className="input-box siginInputbox"
                  type={isRePasswordVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <span
                  className="password-eye"
                  onClick={() => setIsRePasswordVisible((p) => !p)}
                >
                  {isRePasswordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </span>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-x">
            <button
              className="btn-detail"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "Please Wait..." : `Change Password`}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
