import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import Carousel from "react-elastic-carousel";
import residentailImage from "../assets/images/Mask Group 13.png";
import commercialImage from "../assets/images/Mask Group 14.png";
import civilImage from "../assets/images/Mask Group 15.png";
import residentailImage2 from "../assets/images/Group 577.png";
import commercialImage2 from "../assets/images/Group 578.png";
import civilImage2 from "../assets/images/Group 579.png";
import bannnarImage from "../assets/images/Group 580.png";
import "../assets/styles/services.css";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../Config/apiUrl";
import { AiFillPlayCircle } from "react-icons/ai";

function Services({ data }) {
  const history = useHistory();
  const [videoPlay, setVideoPlay] = useState(false);
  const [ended, setEnded] = useState(false);
  let cardData = [
    {
      coverImg: residentailImage,
      iconImg: residentailImage2,
      cardHeading: "Residential construction",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: commercialImage,
      iconImg: commercialImage2,
      cardHeading: "Commercial Construction",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: civilImage,
      iconImg: civilImage2,
      cardHeading: "Civil Construction",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: residentailImage,
      iconImg: residentailImage2,
      cardHeading: "Residentail construction",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: commercialImage,
      iconImg: commercialImage2,
      cardHeading: "Commercial Construction",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      coverImg: civilImage,
      iconImg: civilImage2,
      cardHeading: "Civil Construction",
      cardText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
  ];
  const [isApiCall, setIsApiCall] = useState(false);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
  ];
  return (
    <div className={isApiCall && "op-0"}>
      <section className="Services">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="Services-section" data-aos="fade-up">
                <h1>{data.sec3Heading}</h1>
                <p className="mid">{data.sec3Description}</p>
              </div>
            </Col>
            <Carousel
              itemsToScroll={1}
              // pagination={false}
              breakPoints={breakPoints}
              itemPadding={[0, 20]}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <Row className="d-flex">
                    {pages.map((page) => {
                      const isActivePage = activePage === page;
                      return (
                        <div
                          onClick={() => onClick(page)}
                          key={page}
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            backgroundColor: isActivePage ? "#ccc" : "#eee",
                          }}
                        />
                      );
                    })}
                  </Row>
                );
              }}
            >
              {data["our-services"]?.map((item) => {
                return (
                  <div className="Services-card">
                    <div className="card-img">
                      <div className="card-cover-img">
                        <img
                          src={`${imageUrl}${item.coverImage}`}
                          className="img1"
                          alt="img1"
                        />
                      </div>
                    </div>
                    <div className="card-content">
                      <h3> {item.title}</h3>
                      <p className="mid">{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </Carousel>
            <Col lg={12}>
              <div className="Servicesbtn" data-aos="zoom-out-up">
                <button onClick={() => history.push("/add-project")}>
                  Start your Project
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bannar-section">
        <Container fluid>
          <Row>
            <Col
              lg={12}
              style={{ padding: 0 }}
              onClick={() => {
                setVideoPlay((prev) => !prev);
              }}
            >
              <div className="bannnar-video-box">
                {/* {(!videoPlay || ended )&& (
                  <div className="video-overLay">
                    <AiFillPlayCircle
                      className="player-icon"
                      // onClick={() => setVideoPlay((prev) => !prev)}
                    />
                  </div>
                )} */}
                <ReactPlayer
                  fluid
                  // playing={true}
                  className="react-player"
                  url={data.sec4Video}
                  // loop={true}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Services;
