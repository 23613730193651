import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { AiOutlineClose } from "react-icons/ai";
import { TiWarningOutline } from "react-icons/ti";

export default function ModalSkeleton({
  open,
  setOpen,
  children,
  type,
  noRadius,
  className,
  maxContent,
  areyousure = false,
  rejection,
}) {
  const handleClose = () => setOpen(false);

  const customStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: className ? "30%" : "40%",
    // minWidth: "40%",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: noRadius ? "0px" : "10px",
    minHeight: maxContent ? "max-content" : "40vh",
    // width: className && "30%",
  };

  return (
    <>
      <style>
        {`
      .areYourSureModal{
        width: 30% !important;
        padding: 0 !important;
        border: 0 !important;
         border-radius: 20px !important;
       }
        @media (max-width: 991px) {
         .areYourSureModal{
          width: 47% !important
         }
        }
        @media (max-width: 768px) {
         .areYourSureModal{
          width: 55% !important
         }
        }
        @media (max-width: 600px) {
         .areYourSureModal{
          width: 71% !important
         }
        }
        @media (max-width: 540px) {
         .areYourSureModal{
          width: 90% !important
         }import Header from './header';

        }
        

      }
      
      
      `}
      </style>

      <div className={`${type == "big" && "big_modal_new"}`}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={customStyle} className={`modal-scroll ${className}`}>
              {areyousure ? (
                <>
                  <div className="modal-Header">
                    <span>
                      <TiWarningOutline size={70} />
                    </span>
                    <p>Are you sure</p>
                  </div>
                </>
              ) : rejection ? (
                <div className="modal-Header modal-Header-reject">
                  <p>Rejection Reason</p>
                </div>
              ) : (
                <>
                  <div className="modal-close-icon">
                    <AiOutlineClose className="icon" onClick={handleClose} />
                  </div>
                </>
              )}

              {children}
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
}
