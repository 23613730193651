import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedinIn, FaTwitter, FaGooglePlusG } from "react-icons/fa";
import { BsFillShareFill } from "react-icons/bs";
import ReactPlayer from "react-player";
import Carousel from "react-elastic-carousel";
import PagesSection1 from "../components/PagesSection1";
import HowToOrder from "../components/HowToOrder";
import Contact from "../components/Contact";
import Relaxman from "../assets/images/Relax Man.png";
import sectionimg1 from "../assets/images/Mask Group 2.png";
import sectionimg2 from "../assets/images/Group 580.png";
import sectionimg3 from "../assets/images/Group 621.png";
import sectionimg4 from "../assets/images/Mask Group new.png";
import sectionimg5 from "../assets/images/Mask Group 9.png";
import "../assets/styles/HowToOrderPage.css";
import "../assets/styles/aboutus.css";
import Header from "../components/header";
import Footer from "../components/Footer";
import { AiFillPlayCircle } from "react-icons/ai";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl } from "../Config/apiUrl";

import aos from "aos";
export default function Aboutus() {
  const [isApiCall, setIsApiCall] = useState(false);
  const [apiData, setApiData] = useState({});
  const [videoPlay, setVideoPlay] = useState(false);
  async function getData() {
    const url = BaseURL(
      `cms/page/about_us?crudsArry=how-to-order&crudsArry=our-experts`
    );
    setIsApiCall(true);
    const response = await Get(url, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setApiData(data);
    }
  }
  // console.log("================apiData",apiData)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    aos.init({
      offset: 120,
      delay: 10,
      duration: 1000,
      easing: "ease-in-out",
      mirror: "true",
      once: "false",
      placement: "top-center",
    });
    getData();
  }, []);
  let cardData = [
    {
      coverImg: sectionimg4,
    },
    {
      coverImg: sectionimg5,
    },
    {
      coverImg: sectionimg4,
    },
    {
      coverImg: sectionimg5,
    },
    {
      coverImg: sectionimg4,
    },
    {
      coverImg: sectionimg4,
    },
    {
      coverImg: sectionimg5,
    },
    {
      coverImg: sectionimg4,
    },
  ];
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
  ];
  return (
    <div className={isApiCall && "op-0"}>
      <Header />
      <PagesSection1 data={apiData} />
      <section className="aboutSection">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="aboutSection1">
                <p className="mid">{apiData?.sec2Description1}</p>
              </div>
            </Col>
            <Col lg={12} md={12}>
              <div className="aboutSection1-box">
                <div className="aboutSection-text" data-aos="fade-right">
                  <h1>{apiData?.sec2Heading}</h1>
                  <p className="mid">{apiData?.sec2Description2}</p>
                </div>
                <div className="aboutSection-img" data-aos="fade-left">
                  <img
                    src={`${imageUrl}${apiData?.sec2CoverImage}`}
                    alt="img"
                  />
                </div>
              </div>
            </Col>
            <Col lg={12} md={12} className="aboutSection-col">
              <div className="aboutSection-work">
                <h1>{apiData?.sec3Heading}</h1>
                <p className="mid">{apiData?.sec3Description}</p>
              </div>

              <div className="aboutsection-video">
                {/* {videoPlay == false && <div className="video-overLay">
                  
                  <AiFillPlayCircle className="player-icon"
                  onClick={()=>setVideoPlay(true)}
                   />
                </div>} */}
                <ReactPlayer
                  fluid
                  //  playing={videoPlay}
                  className="react-player"
                  onPlay
                  url={apiData?.sec3Video}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={12} style={{ padding: 0 }}>
              <div className="aboutSection-Meet">
                <div className="aboutSection-Meet-text">
                  <h1>{apiData?.sec4Heading}</h1>
                  <p className="mid">{apiData?.sec4Description}</p>
                </div>
                <Container>
                  <Carousel
                    itemsToScroll={1}
                    // pagination={false}
                    breakPoints={breakPoints}
                    itemPadding={[0, 20]}
                    renderPagination={({ pages, activePage, onClick }) => {
                      return (
                        <Row className="d-flex">
                          {pages.map((page) => {
                            const isActivePage = activePage === page;
                            return (
                              <div
                                onClick={() => onClick(page)}
                                key={page}
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  backgroundColor: isActivePage
                                    ? "#ccc"
                                    : "#eee",
                                }}
                              />
                            );
                          })}
                        </Row>
                      );
                    }}
                  >
                    {apiData["our-experts"]?.map((items) => {
                      return (
                        <div className="meet-img-box">
                          <img src={`${imageUrl}${items.image}`} alt="img" />
                          <div className="meet-img-outer-box">
                            <div className="meet-img-inner-box">
                              <a href={items?.sharableLink}>
                                <BsFillShareFill className="social-icons share-icon" />
                              </a>

                              <h5>{items?.title}</h5>
                              <div className="meet-img-inner-box-text">
                                <p className="xsmall">{items?.subtitle}</p>
                                <p className="xsmall meet-text">
                                  {items?.description}
                                </p>
                              </div>
                              <a href={items?.linkedIn}>
                                <FaLinkedinIn className="social-icons" />
                              </a>

                              <a href={items?.twitter}>
                                <FaTwitter className="social-icons" />
                              </a>

                              <a href={`mailto:${items?.googlePlus}`}>
                                <FaGooglePlusG
                                  style={{ fontSize: 26 }}
                                  className="social-icons"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}
