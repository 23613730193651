import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Row, Col, Dropdown } from "react-bootstrap";
import logo from "../assets/images/logo color.png";
import avtarimage from "../assets/images/avtar-image.png";
import "../assets/styles/header.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BaseURL, apiHeader, fallbackUser } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { signOutRequest } from "../store/Actions/authAction";
import Avatar from "@mui/material/Avatar";
import { RiArrowDropDownLine } from "react-icons/ri";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { imageUrl } from "./../Config/apiUrl";
import EditProfileModal from "./EditProfileModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import BodyLoader from "./BodyLoader";
import { useLocation } from "react-router-dom";
import { BsPlus } from "react-icons/bs";

function Header({ lg }) {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    isLogin,
    access_token: accessToken,
    user,
  } = useSelector((state) => state.authReducer);
  const [isLogoutApiCall, setIsLogoutApiCall] = useState(false);

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePassordModalOpen] =
    useState(false);

  const history = useHistory();
  function redirectOnLink(link) {
    history.push(link);
  }

  // logout user
  async function logoutUser() {
    const url = BaseURL("users/logout");
    setIsLogoutApiCall(true);
    const response = await Post(url, {}, apiHeader(accessToken));
    setIsLogoutApiCall(false);
    if (response !== undefined) {
      dispatch(signOutRequest());
      history.push("/login");
    }
  }

  // get Me
  async function getMe() {
    const meUrl = BaseURL(`users/${user._id}`);
    const response = await Get(meUrl, accessToken, false);
    if (response !== undefined) {
      if (
        response?.data?.data?.data?.status == "pending" ||
        response?.data?.data?.data?.status == "rejected" ||
        response?.data?.data?.data?.status == "blocked"
      ) {
        logoutUser();
      }
    } else {
      // logoutUser();
      dispatch(signOutRequest());
      history.push("/login");
    }
  }

  useEffect(() => {
    getMe();
  }, [window.location.pathname]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Navbar expand="lg">
      <Container className={lg ? "custom-container" : ""}>
        <Navbar.Brand>
          {/* <img
            src={logo}
            className="c-p"
            alt="logo"
            onClick={() => history.push("/")}
            style={{
              width: "200px",
            }}
          /> */}
          <h3 onClick={() => history.push("/")}>Demo</h3>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto navbar-links">
            <Nav.Link
              className={` ${location === "/" && "active-link_nav"}`}
              onClick={() => redirectOnLink("/")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={` ${location === "/services" && "active-link_nav"}`}
              onClick={() => redirectOnLink("/services")}
            >
              Service
            </Nav.Link>
            <Nav.Link
              className={` ${location === "/order" && "active-link_nav"}`}
              onClick={() => redirectOnLink("/order")}
            >
              Order
            </Nav.Link>
            <Nav.Link
              className={` ${location === "/aboutus" && "active-link_nav"}`}
              onClick={() => redirectOnLink("/aboutus")}
            >
              About us
            </Nav.Link>
            <Nav.Link
              className={` ${location === "/contactus" && "active-link_nav"}`}
              onClick={() => redirectOnLink("/contactus")}
            >
              Contact us{" "}
            </Nav.Link>
            <Nav.Link
              className={` ${location === "/blog" && "active-link_nav"}`}
              onClick={() => redirectOnLink("/blog")}
            >
              Blog{" "}
            </Nav.Link>

            {isLogin ? (
              <>
                <div className="avtar-box" onClick={handleClick}>
                  <>
                    <Avatar
                      src={
                        !user?.photo
                          ? fallbackUser
                          : `${imageUrl}${user?.photo}`
                      }
                      alt={`${
                        user?.role === "customer" ? "Client" : "Consultant"
                      }`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = fallbackUser;
                      }}
                      className="headerAvtar"
                    />
                    <span>
                      {user?.name}
                      <RiArrowDropDownLine style={{ fontSize: "24px" }} />
                    </span>
                  </>
                  {user?.role != "customer" && (
                    <span
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Consultant
                    </span>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* <span className="sgin-line"></span> */}
                <Nav.Link
                  className={`linkgreen ${
                    location === "/login" && "active-link_nav"
                  } `}
                  onClick={() => redirectOnLink("/login")}
                >
                  Sign In{" "}
                </Nav.Link>
                {/* <span className="sgin-line"></span> */}
                <Nav.Link
                  className={`linkgreen ${
                    location === "/signup" && "active-link_nav"
                  }`}
                  onClick={() => redirectOnLink("/signup")}
                >
                  Sign up{" "}
                </Nav.Link>
                {!accessToken && (
                  <button
                    className="navbar-btn1"
                    onClick={() => history.push("/add-project")}
                  >
                    <BsPlus className="navbarbtn-icon" />
                    Add project
                  </button>
                )}
              </>
            )}
          </Nav>
        </Navbar.Collapse>

        {/* Edit Profile */}
        {isEditProfileModalOpen && (
          <EditProfileModal
            showModal={isEditProfileModalOpen}
            closeModal={() => setIsEditProfileModalOpen(false)}
          />
        )}
        {/* Update Passoword */}
        <UpdatePasswordModal
          showModal={isUpdatePasswordModalOpen}
          closeModal={() => setIsUpdatePassordModalOpen(false)}
        />

        {isLogoutApiCall && <BodyLoader />}
      </Container>
      <Menu
        className="userMenu_B"
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* Dashboard */}
        <MenuItem
          onClick={() => {
            history.push("/dashboard");
            handleClose();
          }}
        >
          <div className="d-flex w-100 dropdown_box">
            <Nav.Link>Dashboard</Nav.Link>
          </div>
        </MenuItem>

        {/* Edit Profile */}
        <MenuItem
          onClick={() => {
            setIsEditProfileModalOpen(true);
            handleClose();
          }}
        >
          <div className="d-flex w-100 dropdown_box">
            <Nav.Link>Edit Profile</Nav.Link>
          </div>
        </MenuItem>
        {/* Edit Profile */}
        <MenuItem
          onClick={() => {
            setIsUpdatePassordModalOpen(true);
            handleClose();
          }}
        >
          <div className="d-flex w-100 dropdown_box">
            <Nav.Link>Update Password</Nav.Link>
          </div>
        </MenuItem>

        {/* Logout */}
        <MenuItem onClick={handleClose}>
          <div className="d-flex w-100 dropdown_box">
            <Nav.Link onClick={() => logoutUser()}>Logout</Nav.Link>
          </div>
        </MenuItem>
      </Menu>
    </Navbar>
  );
}

export default Header;
