import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
import section2Img1 from "../assets/images/Mask Group 13.png";
import section2Img2 from "../assets/images/Mask Group 14.png";
import section2Img3 from "../assets/images/Mask Group 15.png";
import section2Img4 from "../assets/images/Mask Group 18.png";
import section2Img5 from "../assets/images/Mask Group 17.png";
import section2Img6 from "../assets/images/Group 1225.png";
import section2Img7 from "../assets/images/Group 1226.png";
import section2Img8 from "../assets/images/Group 1227.png";
import section4Img1 from "../assets/images/Developers-img.png";
import section4Img2 from "../assets/images/Contractors-img.png";
import section4Img3 from "../assets/images/HomeBuilders-img.png";
import section4Img4 from "../assets/images/Architects-img.png";
import PagesSection1 from "../components/PagesSection1";
import "../assets/styles/servicespage.css";
import aos from "aos";
import Header from "../components/header";
import Footer from "../components/Footer";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl } from "../Config/apiUrl";
import { useHistory } from "react-router-dom";
import BodyLoader from "../components/BodyLoader";

export default function Servicespage() {
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [apiData, setApiData] = useState({});

  async function getData() {
    const url = BaseURL(
      `cms/page/services?crudsArry=our-services&crudsArry=greatest-asset-time&crudsArry=quantities-for`
    );
    setIsApiCall(true);
    const response = await Get(url, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setApiData(data);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);

  useEffect(() => {
    aos.init({
      offset: 120,
      delay: 10,
      duration: 1000,
      easing: "ease-in-out",
      mirror: "true",
      once: "false",
      placement: "top-center",
    });
    getData();
  }, []);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 998, itemsToShow: 4 },
  ];
  return (
    <>
      {/* <style>{`.Servicespage-section2 {
        background-image:url(${imageUrl + apiData?.sec1CoverImage})
    }`}</style> */}
      <div className={isApiCall && "op-0"}>
        <Header />
        <PagesSection1 data={apiData} />
        <section className="Servicespage-section2">
          <Container>
            <Row>
              <Col lg={12}>
                {apiData["our-services"]?.map((item, index) => {
                  if (index % 2 != 0) {
                    return (
                      <>
                        <Row className="row_one">
                          <Col
                            className="service-col"
                            lg={{ span: 6, order: 1 }}
                            md={{ span: 12, order: 2 }}
                          >
                            <div
                              className="Servicespage-section2-rightside Services-img-box"
                              data-aos="fade-right"
                            >
                              <img
                                src={`${imageUrl}${item?.coverImage}`}
                                alt="sectionImage"
                              />
                            </div>
                          </Col>
                          <Col
                            lg={{ span: 6, order: 2 }}
                            md={{ span: 12, order: 1 }}
                          >
                            <div
                              className="Servicespage-section2-leftside"
                              data-aos="fade-left"
                            >
                              <h2>{item?.title}</h2>
                              <p className="mid">{item?.description}</p>
                              <div
                                className="Servicespage-btns"
                                data-aos="fade-left"
                              >
                                <button onClick={() => history.push("/signup")}>
                                  Get Started
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Row className="row_two">
                          <Col
                            lg={{ span: 6, order: 1 }}
                            md={{ span: 12, order: 1 }}
                          >
                            <div
                              className="Servicespage-section2-leftside"
                              data-aos="fade-right"
                            >
                              <h2>{item?.title}</h2>
                              <p className="mid">{item?.description}</p>
                              <div
                                className="Servicespage-btns"
                                data-aos="fade-right"
                              >
                                <button onClick={() => history.push("/signup")}>
                                  Get Started
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={{ span: 6, order: 2 }}
                            md={{ span: 12, order: 2 }}
                          >
                            <div
                              className="Servicespage-section2-rightside Services-img-box"
                              data-aos="fade-left"
                            >
                              <img
                                src={`${imageUrl}${item?.coverImage}`}
                                alt="sectionImage"
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    );
                  }
                })}
              </Col>
            </Row>
          </Container>
        </section>
        <section className="Servicespage-section3">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <div className="Servicespage-section3-heading">
                  <h1>{apiData?.sec3Heading}</h1>
                  <p className="mid">{apiData?.sec3Description}</p>
                </div>
              </Col>
              {apiData["greatest-asset-time"]?.map((item, index) => {
                return (
                  <>
                    <Col lg={4} md={6} sm={12}>
                      <div className="Servicespage-section3-box">
                        <div className="Servicespage-section3-img Services-img-box sec-2">
                          <img src={`${imageUrl}${item?.image}`} alt="" />
                        </div>
                        <div className="Servicespage-section3-content">
                          <p className="big">{item?.description}</p>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}
            </Row>
          </Container>
        </section>
        <section className="Servicespage-section4">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <div className="Servicespage-section4-heading">
                  <h1>{apiData?.sec4Heading}</h1>
                </div>
              </Col>
              <Carousel
                itemsToScroll={2}
                // pagination={false}
                breakPoints={breakPoints}
                itemPadding={[0, 20]}
                renderPagination={({ pages, activePage, onClick }) => {
                  return (
                    <Row className="d-flex">
                      {pages.map((page) => {
                        const isActivePage = activePage === page;
                        return (
                          <div
                            onClick={() => onClick(page)}
                            key={page}
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "5px",
                              marginRight: "5px",
                              marginTop: '10px',
                              backgroundColor: isActivePage ? "#ccc" : "#eee",
                            }}
                          />
                        );
                      })}
                    </Row>
                  );
                }}
              >
                {apiData["quantities-for"]?.map((item) => {
                  return (
                    <div className="Servicespage-section4-box">
                      <div className="Servicespage-section4-img Services-img-box">
                        <img src={`${imageUrl}${item?.image}`} alt="img" />
                      </div>
                      <div className="Servicespage-section4-content">
                        <p className="big">{item?.title}</p>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
              <Col lg={12}>
                <div className="Servicespage-btns2">
                  <button onClick={() => history.push("/signup")}>
                    Get Started Today!
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
