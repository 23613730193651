import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import "../assets//styles/Dashboardpage.css";
import "../assets/styles/customerdashboard.css";
import { Get } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SideNavBar from "../components/SideNavBar";
import MessageSideBar from "../components/MessageSideBar";
import ProjectCards from "../components/ProjectCards";
import Invoice from "../components/Invoice";
import Header from "../components/header.jsx";
import NullDataComponent from "../components/NullDataComponent";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import { currencies } from "../pages/currencies";
import useWindowSize from "../hooks/useWindowSize";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import { isMobileViewHook } from "../hooks/IsMobileViewHook";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import TableRowsLoader from "../components/TableRowsLoader";
import CardLoader from "../components/CardLoader";
var numeral = require("numeral");
export default function CustomerDashboard() {
  // for sidebar nav
  const [width, height] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 },
  ];
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [payments, setPayments] = useState([]);
  const [projects, setProjects] = useState([]);

  // get Data
  async function getData() {
    const url = BaseURL("customer/dashboardData");
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setPayments(data?.payments);
      setProjects(data?.projects);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    isMobileViewHook(setIsMobile);
  }, []);

  return (
    <>
      <Header lg />
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col xs={12} lg={9}>
            <Col lg={12}>
              <div className="Dashboardpage-project invoice">
                <h2>My Projects</h2>
                {projects?.length > 0 && (
                  <div className="Dashboardpage-project-btn">
                    <button
                      onClick={() => {
                        history.push("/myproject");
                      }}
                    >
                      View More
                    </button>
                  </div>
                )}
              </div>
              {isApiCall ? (
                <CardLoader size={400} />
              ) : (
                <>
                  {projects?.length > 1 ? (
                    <Col md={12}>
                      {!isMobile && projects?.length > 0 && (
                        <div className="projectCard-box c-p">
                          <Carousel
                            itemsToScroll={1}
                            pagination={false}
                            breakPoints={breakPoints}
                            itemPadding={[10, 20]}
                          >
                            {projects?.map((items, index) => {
                              return (
                                <ProjectCards
                                  key={items?._id}
                                  currency={items?.currency}
                                  title={items?.title}
                                  description={items?.description}
                                  amount={items?.amount}
                                  timeline={items?.timeline}
                                  projectStatus={items?.projectStatus}
                                  onClick={() => {
                                    history.push({
                                      pathname: "/detailPage",
                                      state: items?._id,
                                    });
                                  }}
                                />
                              );
                            })}
                          </Carousel>
                        </div>
                      )}
                      {isMobile && projects?.length > 0 && (
                        <div className="projectCard-box">
                          <Swiper spaceBetween={30} slidesPerView={1}>
                            {projects?.map((items, index) => {
                              return (
                                <SwiperSlide>
                                  <ProjectCards
                                    key={items?._id}
                                    currency={items?.currency}
                                    title={items?.title}
                                    description={items?.description}
                                    amount={items?.amount}
                                    timeline={items?.timeline}
                                    projectStatus={items?.projectStatus}
                                    onClick={() => {
                                      history.push({
                                        pathname: "/detailPage",
                                        state: items?._id,
                                      });
                                    }}
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      )}
                    </Col>
                  ) : (
                    <Col md={6}>
                      {!isMobile && projects?.length > 0 && (
                        <div className="projectCard-box c-p">
                          {projects?.map((items, index) => {
                            return (
                              <ProjectCards
                                key={items?._id}
                                currency={items?.currency}
                                title={items?.title}
                                description={items?.description}
                                amount={items?.amount}
                                timeline={items?.timeline}
                                projectStatus={items?.projectStatus}
                                onClick={() => {
                                  history.push({
                                    pathname: "/detailPage",
                                    state: items?._id,
                                  });
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                      {isMobile && projects?.length > 0 && (
                        <div className="projectCard-box">
                          <Swiper spaceBetween={30} slidesPerView={1}>
                            {projects?.map((items, index) => {
                              return (
                                <SwiperSlide>
                                  <ProjectCards
                                    key={items?._id}
                                    currency={items?.currency}
                                    title={items?.title}
                                    description={items?.description}
                                    amount={items?.amount}
                                    timeline={items?.timeline}
                                    projectStatus={items?.projectStatus}
                                    onClick={() => {
                                      history.push({
                                        pathname: "/detailPage",
                                        state: items?._id,
                                      });
                                    }}
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      )}
                    </Col>
                  )}
                </>
              )}

              {!isApiCall && projects.length === 0 && (
                <div className="p-d-null-data">
                  <NullDataComponent c1 text={"No Project Found."} />
                </div>
              )}
            </Col>
            <Col lg={12}>
              <div className="Dashboardpage-invoice">
                <h2>Invoices</h2>
                {payments.length > 0 && (
                  <div className="Dashboardpage-invoice-btn">
                    <button
                      onClick={() => {
                        history.push("/Invoice");
                      }}
                    >
                      View More
                    </button>
                  </div>
                )}
              </div>
              {isApiCall ? (
                <TableRowsLoader />
              ) : (
                <>{payments?.length > 0 && <Invoice payments={payments} />}</>
              )}

              {!isApiCall && payments.length === 0 && (
                <div className="p-d-null-data">
                  <NullDataComponent c2 text={"No Invoices Found."} />
                </div>
              )}
            </Col>
          </Col>
          {/* <Col lg={3}>
              <MessageSideBar />
            </Col> */}
        </Row>
      </Container>
      <Footer />
      {/* </section> */}
      {/* {isApiCall && <BodyLoader />} */}
    </>
  );
}
