import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PagesSection1 from "../components/PagesSection1";
import Contact from "../components/Contact";
import "../assets/styles/Footer.css";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import "../assets/styles/contactuspage.css";
import Header from "../components/header";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import { apiHeader, BaseURL , imageUrl } from "../Config/apiUrl";
import { Post, Get } from "../Axios/AxiosFunctions";

export default function Contactuspage() {
  const [isApiCall, setIsApiCall] = useState(false);
  const [apiData, setApiData] = useState({});

  async function getData() {
    const url = BaseURL(`cms/page/contact_us?crudsArry=social-icons`);
    setIsApiCall(true);
    const response = await Get(url, false);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setApiData(data);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Header />
      <PagesSection1 data={apiData} />
      <section className="contactSection">
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <div className="contactsection1">
                <h1>{apiData?.sec2Heading}</h1>
                <p className="mid">{apiData?.sec2Description}</p>
                <div className="rightside">
                  <HiOutlineMail className="social-icons" />
                  <span>
                    <a href={`mailto:${apiData?.email}`}>{apiData?.email}</a>
                  </span>{" "}
                  <br />
                  <div className="con contact-phone">
                    <span>
                      <BsFillTelephoneFill className="social-icons" />
                    </span>
                    <div>
                      <a href={`tel:+${apiData?.mobileNo}`}>{apiData?.mobileNo}</a> <br />
                      <a href={`tel:+${apiData?.phoneNo}`}>{apiData?.phoneNo}</a>
                    </div>
                  </div>
                </div>
                <div className="leftside">
                <div className="icons-div">
                {
                  apiData['social-icons']?.map((item,index)=>(
                    <div className="social-icons-new c-p">
                    <a target={"_blank"} href={item?.socialUrl}>
                    <img src={`${imageUrl}${item?.image}`} />
                  </a>
                  </div>
                  ))
                }
                </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="contactsection2">
                <Contact isHidetitle />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
}
