import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, recordsLimit } from "../Config/apiUrl";
import SideNavBar from "../components/SideNavBar";
import ProjectCards from "../components/ProjectCards";
import { Get } from "../Axios/AxiosFunctions";
import Header from "../components/header";
import NullDataComponent from "../components/NullDataComponent";
import BodyLoader from "../components/BodyLoader";
import PaginationB from "../components/PaginationB";
import ResponsiveMenu from "../components/ResponsiveMenu";
import Footer from "../components/Footer";
import CardLoader from "../components/CardLoader";

function AssignedProject() {
  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [isApiCall, setIsApiCall] = useState(false);
  const [projects, setProjects] = useState([]);

  // getData
  async function getData(_page) {
    const url = BaseURL(
      `freelancer/assignProjects?limit=${recordsLimit}&page=${_page}`
    );
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setProjects(data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);
  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <>
      <Header lg />
      <Container className="custom-container py">
        <Row>
          <Col lg={3} md={false} className="display-sm-none">
            <SideNavBar />
          </Col>
          <Col sm={12} className="display-md-none">
            <ResponsiveMenu />
          </Col>
          <Col lg={9} md={12} sm={12} className="assignedProject-Container">
            <h2 className="my-4">Assigned Projects</h2>
            <div className="MyprojectCard-box freelancer-card">
              <Row className="assignedProjectCard_row">
                {isApiCall &&
                  [1, 2, 3, 4, 5, 6].map((_) => {
                    return (
                      <Col md={4} className="MyprojectCard-container">
                        <div className="MyprojectCard-box">
                          <CardLoader size={400} />
                        </div>
                      </Col>
                    );
                  })}
                {projects?.length > 0 &&
                  !isApiCall &&
                  projects.map((items, index) => {
                    return (
                      <Col lg={4} md={6} sm={12} xs={12}>
                        <ProjectCards
                          key={items?._id}
                          title={items?.accecptedPorposalByFreelancer?.title}
                          description={
                            items?.accecptedPorposalByFreelancer?.description
                          }
                          amount={items?.accecptedPorposalByFreelancer?.amount}
                          timeline={items?.timeline}
                          projectStatus={
                            items?.accecptedPorposalByFreelancer?.status
                          }
                          onClick={() => {
                            history.push({
                              pathname: "/project-detail",
                              search: `loc=1`,
                              state: items?._id,
                            });
                          }}
                        />
                      </Col>
                    );
                  })}
              </Row>

              {projects.length === 0 && (
                <div className="p-d-null-data mt-25-vp">
                  <NullDataComponent
                    c1
                    text={
                      isApiCall ? "Please Wait..." : "No Project assigned yet."
                    }
                  />
                </div>
              )}

              {totalCount > 1 && (
                <PaginationB
                  page={page}
                  setPage={setPage}
                  totalCount={totalCount}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {/* {isApiCall && <BodyLoader />} */}
      <Footer />
    </>
  );
}

export default AssignedProject;
